import './App.css';
import {useEffect, useState} from "react";
import {CruxJs, CruxTrackModule} from '@WahooFitness/crux-packed/dist/index';
import 'react-tabs/style/react-tabs.css';
import MapTab from "./ui/MapTab";

function App() {

    const [cruxJs, setCruxJs] = useState(null);

    useEffect(() => {

        // Load the CruxJs WASM module if necessary
        if (!cruxJs) {
            (async () => {
                console.log("Loading CruxJs")
                const wasm = await CruxTrackModule.default(undefined);
                console.log("CruxJs loaded")
                setCruxJs(new CruxJs(wasm));
            })();
        } else {
            console.log("CruxJs already loaded")
        }
    }, [cruxJs]);


    return (
        <>
            {/*https://github.com/reactjs/react-tabs*/}
            {/*<Tabs>*/}
            {/*    <TabList>*/}
            {/*        <Tab>MapTab</Tab>*/}
            {/*        <Tab>Crux</Tab>*/}
            {/*    </TabList>*/}

            {/*    <TabPanel>*/}
            {/*        <TabMap cruxjs={cruxjs}/>*/}
            {/*    </TabPanel>*/}
            {/*    <TabPanel>*/}
            {/*        <h1>CruxJS React Sample</h1>*/}
            {/*        <p>CruxJS Log Level={cruxjs ? CruxJsUtils.getLogLevel(cruxjs) : "?"}</p>*/}
            {/*    </TabPanel>*/}

            {/*</Tabs>*/}

            <MapTab cruxJs={cruxJs}/>

        </>
    );
}

export default App;