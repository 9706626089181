import {Button, Modal} from "react-bootstrap";

export function DownloadRouteOrRideModal(props) {

    return (

        <Modal show={props.show} onHide={() => {
            props.setShow(false)
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Download</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{}}>
                    <label><h4>Download route</h4></label><br/>
                    <Button variant="outline-danger" onClick={() => {
                        props.onSelect(true, "FIT")
                    }}>FIT
                    </Button>
                    <Button variant="outline-danger" onClick={() => {
                        props.onSelect(true, "JSON")
                    }}>JSON
                    </Button><br/>
                    <Button variant="outline-danger" onClick={() => {
                        props.onSelect(true, "LocCSV")
                    }}>Location Pt CSV
                    </Button>
                    <Button variant="outline-danger" onClick={() => {
                        props.onSelect(true, "EleCSV")
                    }}>Elevation Pt CSV
                    </Button>
                    <Button variant="outline-danger" onClick={() => {
                        props.onSelect(true, "ClimbsCSV")
                    }}>Climbs CSV
                    </Button>
                    <br/><br/>

                    <label><h4>Download ride</h4></label><br/>
                    <Button variant="outline-primary" onClick={() => {
                        props.onSelect(false, "FIT")
                    }}>FIT
                    </Button>
                    <Button variant="outline-primary" onClick={() => {
                        props.onSelect(false, "JSON")
                    }}>JSON
                    </Button><br/>
                    <Button variant="outline-primary" onClick={() => {
                        props.onSelect(false, "LocCSV")
                    }}>Location Pt CSV
                    </Button>
                </div>
            </Modal.Body>
        </Modal>

    );


}