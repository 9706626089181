import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import React from "react";

function MyButton({tooltip, variant, selected, onClick, img}) {

    return (
        <>

            <OverlayTrigger
                key={tooltip}
                placement="bottom"
                overlay={
                    <Tooltip id={tooltip}>
                        {tooltip}
                    </Tooltip>
                }
            >
                <Button variant={variant} size="sm" onClick={onClick} active={selected}>{img}</Button>
            </OverlayTrigger>

        </>
    );
}

export default MyButton;