// noinspection JSPotentiallyInvalidConstructorUsage

import {MapBoxApi} from "./MapBoxApi";

import {crux_extras, crux_location_utils, CruxRoute} from '@WahooFitness/crux-packed/dist/index';

export class ClimbDetector {

    cruxJs;
    cruxRoutes = [];
    /** Array of object with fields lat, lng*/
    destinations = [];
    /** Object with fields lat, lng*/
    userLocation = {};

    /**
     *
     * @param cruxJs
     * @param userLocation Object with fields lat, lng
     * @param onComplete
     */
    constructor(cruxJs, userLocation, onComplete) {

        let routeCount = 12;
        let routeDistanceM = ClimbDetector.getClimbRouteRadiusM();

        let _crux_location_utils = new crux_location_utils(cruxJs);
        let _crux_extras = new crux_extras(cruxJs);

        this.cruxJs = cruxJs;
        this.userLocation = userLocation;
        this.onComplete = onComplete;

        for (let i = 0; i < routeCount; i++) {

            let bearingDeg = i * (360 / routeCount);

            let latLonCPtrOwned = _crux_location_utils.clone_destination_from_location(userLocation.lat, userLocation.lng, bearingDeg, routeDistanceM);
            let latDeg1 = _crux_extras.crux_lat_lon_get_lat_deg(latLonCPtrOwned);
            let lonDeg1 = _crux_extras.crux_lat_lon_get_lon_deg(latLonCPtrOwned);
            _crux_extras.free(latLonCPtrOwned);

            this.destinations.push({lat: latDeg1, lng: lonDeg1})

        }
        console.log("userLocation", this.userLocation)
        console.log("destinations", this.destinations)


    }


    start() {

        let this_ = this;
        let remaining = this.destinations.length;

        let onMapBoxComplete = function onComplete(mapboxDirections) {

            if (mapboxDirections?.routes?.length > 0) {
                console.log("<< MapBoxApi fetchDirections OK")
                let mapboxRoute = mapboxDirections.routes[0];
                let cruxRoute = new CruxRoute(this_.cruxJs);
                cruxRoute.buildFromMapboxRoute(mapboxRoute);
                cruxRoute.calculateClimbs();
                this_.cruxRoutes.push(cruxRoute);
            } else {
                console.log("<< MapBoxApi fetchDirections FAILED")
            }

            remaining--;
            if (remaining === 0) {
                this_.onComplete(this_.cruxRoutes);
            }
        };


        for (let i = 0; i < this.destinations.length; i++) {
            const destination = this.destinations[i];
            let waypoints = [this.userLocation, destination];
            MapBoxApi.fetchDirectionsAndElevations(waypoints, onMapBoxComplete)
        }
    }

    static getClimbRouteRadiusM() {
        let value = localStorage.getItem("ClimbDetector.ClimbRouteRadiusM");
        return value ? value : 1000;
    }

    static setClimbRouteRadiusM(value) {
        console.log("ClimbDetector setClimbRouteRadiusM", value)
        localStorage.setItem("ClimbDetector.ClimbRouteRadiusM", value ? value : 1000);
    }
}