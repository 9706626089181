export class GoogleChartsUtils {

    static TITLES = ["dist", "elevRaw", "elevOffClimb", "elevOnClimb"];
    static EMPTY = [this.TITLES, [0.0, 0.0, 0.0, 0.0]];

    /**
     * This function we ported from crux_route_save_elevation_analysis_to_csv()
     * @param cruxRoute
     * @return {*[]}
     */
    static getElevData(cruxRoute) {

        let elevData = [];

        const lp_count = cruxRoute.getLocationPointCount(); // The number of location/crumb points in the route
        if (lp_count < 2) {
            return this.EMPTY;
        }

        let delta_idx = Math.ceil(lp_count / 1000);

        let lp_idx = 0;
        let lp; // object containing fields distance_m, elevation_m, lat_deg, lon_deg

        const ep_count = cruxRoute.getElevationPointCount();
        if (ep_count < 2) {
            return this.EMPTY;
        }
        let ep_idx = 0;
        let ep; // object containing fields distance_m, elevation_m, ascent_m, grade_perc

        const climb_count = cruxRoute.getClimbCount();
        let climb_idx = 0;
        let climb; // object with fields start_location_point_index, end_location_point_index, start_location_point_index,end_elevation_point_index distance_m, ascent_m, grade_perc, classification, classification_str

        elevData.push(this.TITLES)

        let in_climb = false;
        let first = true;

        while (true) {


            lp = lp_idx < lp_count ? cruxRoute.getLocationPoint(lp_idx) : null;
            let lp_distance_m = lp ? lp.distance_m : 0xFFFFFFFF;

            ep = ep_idx < ep_count ? cruxRoute.getElevationPoint(ep_idx) : null;
            let ep_distance_m = ep ? ep.distance_m : 0xFFFFFFFF;

            climb = climb_idx < climb_count ? cruxRoute.getClimb(climb_idx) : null;
            in_climb = climb && lp_idx >= climb.start_location_point_index && lp_idx <= climb.end_location_point_index;
            if (climb && lp_idx > climb.end_location_point_index) {
                climb_idx++;
            }

            if (first) {
                elevData.push([lp_distance_m - 1, lp.elevation_m, lp.elevation_m, lp.elevation_m])
                first = false;
            }


            if (lp_distance_m === ep_distance_m) {

                if (lp_distance_m === 0xFFFFFFFF) {
                    break;
                }

                // log both
                if (in_climb) {
                    elevData.push([lp_distance_m, lp.elevation_m, null, ep.elevation_m])
                } else {
                    elevData.push([lp_distance_m, lp.elevation_m, ep.elevation_m, null])
                }

                lp_idx += delta_idx;
                ep_idx += delta_idx;

            } else if (lp_distance_m < ep_distance_m) {
                // log location point
                elevData.push([lp_distance_m, lp.elevation_m, null, null])

                lp_idx += delta_idx;
            } else {
                // log elevation point
                if (in_climb) {
                    elevData.push([ep_distance_m, null, null, ep.elevation_m])
                } else {
                    elevData.push([ep_distance_m, null, ep.elevation_m, null])
                }

                ep_idx += delta_idx;
            }
        }

        return elevData;


    }

}