import baseline_360_black_24dp from '../icons/baseline_360_black_24dp.png';
import baseline_done_black_24dp from '../icons/baseline_done_black_24dp.png'; // See https://fonts.google.com/icons?selected=Material+Icons&icon.category=maps
import baseline_favorite_black_24dp from '../icons/baseline_favorite_black_24dp.png';
import baseline_home_black_24dp from '../icons/baseline_home_black_24dp.png';
import baseline_not_listed_location_black_24dp from '../icons/baseline_not_listed_location_black_24dp.png';
import baseline_place_black_24dp from '../icons/baseline_place_black_24dp.png';
import baseline_report_problem_black_24dp from '../icons/baseline_report_problem_black_24dp.png';
import baseline_roundabout_left_black_24dp from '../icons/baseline_roundabout_left_black_24dp.png';
import baseline_roundabout_right_black_24dp from '../icons/baseline_roundabout_right_black_24dp.png';
import baseline_straight_black_24dp from '../icons/baseline_straight_black_24dp.png';
import baseline_trip_origin_black_24dp from '../icons/baseline_trip_origin_black_24dp.png';
import baseline_turn_left_black_24dp from '../icons/baseline_turn_left_black_24dp.png';
import baseline_turn_right_black_24dp from '../icons/baseline_turn_right_black_24dp.png';
import baseline_turn_sharp_left_black_24dp from '../icons/baseline_turn_sharp_left_black_24dp.png';
import baseline_turn_sharp_right_black_24dp from '../icons/baseline_turn_sharp_right_black_24dp.png';
import baseline_turn_slight_left_black_24dp from '../icons/baseline_turn_slight_left_black_24dp.png';
import baseline_turn_slight_right_black_24dp from '../icons/baseline_turn_slight_right_black_24dp.png';
import baseline_u_turn_left_black_24dp from '../icons/baseline_u_turn_left_black_24dp.png';
import baseline_u_turn_right_black_24dp from '../icons/baseline_u_turn_right_black_24dp.png';
import baseline_terrain_black_24dp from '../icons/baseline_terrain_black_24dp.png';
// import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions' // See https://github.com/mapbox/mapbox-gl-directions/issues/157#issuecomment-566061339
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import {crux_route_common, crux_route_course_point_type_e, CruxRoute} from '@WahooFitness/crux-packed/dist/index';
import {Popup} from "mapbox-gl";


export class MapView {

    /** This helps me a lot to visualise all the layers/sources in this map */
    ID = {
        BoltAppRoute: {
            Line: {
                sourceId: "BoltAppRoute.Line.sourceId",
                layerId: "BoltAppRoute.Line.layerId"
            },
            StartPt: {
                sourceId: "BoltAppRoute.StartPt.sourceId",
                layerId: "BoltAppRoute.StartPt.layerId",
            }
        },
        BoltAppClimb: {
            Line: {
                sourceId: "BoltAppClimb.Line.sourceId.",
                layerId: "BoltAppClimb.Line.layerId."
            },
            StartPt: {
                sourceId: "BoltAppClimb.StartPt.sourceId.",
                layerId: "BoltAppClimb.StartPt.layerId.",
            },
        },
        BoltAppSegment: {
            Line: {
                sourceId: "BoltAppSegment.Line.sourceId.",
                layerId: "BoltAppSegment.Line.layerId."
            },
            StartPt: {
                sourceId: "BoltAppSegment.StartPt.sourceId.",
                layerId: "BoltAppSegment.StartPt.layerId.",
            },
        },
        Route: {
            ClosestPt: {
                sourceId: "Route.ClosestPt.sourceId.",
                layerId: "Route.ClosestPt.layerId.",
            },
            Directions: {
                sourceId: "Route.Directions.sourceId.",
                Line: {
                    layerId: "Route.Directions.Line.layerId.",
                },
                Pts: {
                    layerId: "Route.Directions.Pts.layerId.",
                }

            },
            /** The route course points */
            CoursePts: {
                /** The route course points need a point source (for the icons) */
                Pts: {
                    sourceId: "CoursePts.Pts.sourceId",
                    PopupArea: {
                        layerId: "CoursePts.Pts.PopupArea.layerId"
                    },
                    Icon: {
                        layerId: "CoursePts.Pts.Icon.layerId"
                    }
                },
                /** The route course points need a different type of source for the circles */
                Circles: {
                    sourceId: "CoursePts.Circles.sourceId",
                    Circle: {
                        layerId: "CoursePts.Circles.Circle.layerId"
                    },

                }
            }
        }
    }


    /** @type {mapboxgl.Map} */
    map;
    routeClimbPaths = null;
    routeClimbInfos = null;

    // showDirections(show) {
    //     console.log("showDirections", show)
    //     if (show) {
    //         if (!this.map.hasControl(this.directions))
    //             this.map.addControl(this.directions, 'top-left');
    //     } else {
    //         if (this.map.hasControl(this.directions)) {
    //             this.map.removeControl(this.directions);
    //         }
    //     }
    // }


    constructor(map, accessToken) {
        this.map = map;

        // When using both MapboxDirections + MapboxGeocoder there is a bug and the MapboxGeocoder looks weird
        // https://github.com/mapbox/mapbox-gl-directions/issues/223
        // https://www.npmjs.com/package/@mapbox/mapbox-gl-directions
        // https://docs.mapbox.com/mapbox-gl-js/example/mapbox-gl-directions/
        // this.directions = new MapboxDirections({
        //     accessToken: accessToken,
        //     unit: 'metric',
        //     profile: 'mapbox/cycling'
        // });

        // Add the control to the map.
        // See https://github.com/mapbox/mapbox-gl-geocoder
        // See https://www.npmjs.com/package/@mapbox/mapbox-gl-geocoder
        // See https://github.com/mapbox/mapbox-gl-geocoder/blob/main/API.md
        this.geocoder = new MapboxGeocoder({
            accessToken: accessToken,
            collapsed: false,
            mapboxgl: this.map,
        });

        this.map.addControl(this.geocoder, 'top-right');

        let icons = [
            [baseline_done_black_24dp, "baseline_done_black_24dp"],
            [baseline_favorite_black_24dp, "baseline_favorite_black_24dp"],
            [baseline_not_listed_location_black_24dp, "baseline_not_listed_location_black_24dp"],
            [baseline_place_black_24dp, "baseline_place_black_24dp"],
            [baseline_report_problem_black_24dp, "baseline_report_problem_black_24dp"],
            [baseline_roundabout_left_black_24dp, "baseline_roundabout_left_black_24dp"],
            [baseline_roundabout_right_black_24dp, "baseline_roundabout_right_black_24dp"],
            [baseline_straight_black_24dp, "baseline_straight_black_24dp"],
            [baseline_trip_origin_black_24dp, "baseline_trip_origin_black_24dp"],
            [baseline_turn_left_black_24dp, "baseline_turn_left_black_24dp"],
            [baseline_turn_right_black_24dp, "baseline_turn_right_black_24dp"],
            [baseline_turn_sharp_left_black_24dp, "baseline_turn_sharp_left_black_24dp"],
            [baseline_turn_sharp_right_black_24dp, "baseline_turn_sharp_right_black_24dp"],
            [baseline_turn_slight_left_black_24dp, "baseline_turn_slight_left_black_24dp"],
            [baseline_turn_slight_right_black_24dp, "baseline_turn_slight_right_black_24dp"],
            [baseline_u_turn_left_black_24dp, "baseline_u_turn_left_black_24dp"],
            [baseline_u_turn_right_black_24dp, "baseline_u_turn_right_black_24dp"],
            [baseline_home_black_24dp, "baseline_home_black_24dp"],
            [baseline_360_black_24dp, "baseline_360_black_24dp"],
            [baseline_terrain_black_24dp, "baseline_terrain_black_24dp"],
        ];

        icons.forEach(icon => {
            map.loadImage(icon[0], function (error, image) {
                if (error) throw error;
                map.addImage(icon[1], image);
            });
        })


    }

    #getCoursePointCircleData(lat_deg, lon_deg, instruction, type, bearing) {
        // routeCoursePointCircles
        // We want to add 20m radius circles
        // See https://ogeek.cn/qa/?qa=774045/
        let points = 10;
        var km = 0.020; // 20m
        var polyPoints = [];
        var distanceX = km / (111.320 * Math.cos(lat_deg * Math.PI / 180));
        var distanceY = km / 110.574;
        var theta, x, y;
        for (var i = 0; i < points; i++) {
            theta = (i / points) * (2 * Math.PI);
            x = distanceX * Math.cos(theta);
            y = distanceY * Math.sin(theta);
            polyPoints.push([lon_deg + x, lat_deg + y]);
        }
        polyPoints.push(polyPoints[0]);
        return {
            "type": "Feature",
            'properties': {
                'description': instruction,
                'icon-image': this.getIcon(type),
                'icon-rotate': bearing,
                'icon-ignore-placement': false,
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [polyPoints]
            }
        };
    }

    /**
     * Add climbs to the map
     * @param {CruxRoute} cruxRoute the crux route containing climbs
     * @param {Boolean} clearPrevious true to clear any previous climbs before adding these
     */
    addRouteClimbsFromCruxRoute(cruxRoute, clearPrevious) {


        // routeClimbInfos
        if (!this.routeClimbPaths || clearPrevious) {
            this.routeClimbPaths = {
                'type': 'FeatureCollection',
                'features': []
            };
        }

        // routeClimbInfos
        if (!this.routeClimbInfos || clearPrevious) {
            this.routeClimbInfos = {
                'type': 'FeatureCollection',
                'features': []
            };
        }

        for (let i = 0; i < cruxRoute?.getClimbCount(); i++) {

            console.log("climb ", i);

            let climb = cruxRoute.getClimb(i);

            let locationPoint0 = cruxRoute.getLocationPoint(climb.start_location_point_index);

            // routeClimbInfos

            let grade_perc = climb.grade_perc.toFixed(0);
            let ascent_m = climb.ascent_m.toFixed(0);
            let distance_m = climb.distance_m.toFixed(0);
            let climbDescription = "<b>Climb " + (i + 1) + "</b><br/>" +
                "Distance: " + distance_m + "m<br/>" +
                "Ascent: " + ascent_m + "m<br/>" +
                "Grade: " + grade_perc + "%<br/>" +
                "Class: " + climb.classification_str;

            this.routeClimbInfos.features.push({
                'type': 'Feature',
                'properties': {
                    'description': climbDescription,
                },
                'geometry': {
                    'type': 'Point',
                    'coordinates': [locationPoint0.lon_deg, locationPoint0.lat_deg]
                }
            });


            let coordinates = [];
            for (let j = climb.start_location_point_index; j <= climb.end_location_point_index; j++) {
                let locationPoint = cruxRoute.getLocationPoint(j)
                let coordinate = [locationPoint.lon_deg, locationPoint.lat_deg];
                coordinates.push(coordinate)
            }
            this.routeClimbPaths.features.push({
                'type': 'Feature',
                'properties': {
                    'description': climbDescription,
                },
                'geometry': {
                    'type': 'LineString',
                    'coordinates': coordinates
                }
            });
        }


        // routeClimbInfos
        this.regEmptySource("routeClimbInfos");
        this.map.getSource("routeClimbInfos").setData(this.routeClimbInfos);

        // routeClimbs
        this.regEmptySource("routeClimbs");
        this.map.getSource("routeClimbs").setData(this.routeClimbPaths);

    }

    clearRideDirections() {
        this.clearSourceData("rideDirections")
    }


    clearRoute() {

        this.clearSourceData(this.ID.Route.Directions.sourceId)
        this.clearSourceData("routeClimbs")
        this.clearSourceData("routeClimbInfos")
        this.clearSourceData(this.ID.Route.CoursePts.Pts.sourceId)
        this.clearSourceData(this.ID.Route.CoursePts.Circles.sourceId)
        this.clearSourceData(this.ID.Route.ClosestPt.sourceId)

    }


    /** Clears all data for the specified source */
    clearSourceData(sourceId) {
        //console.log("clearSourceData sourceId=" + sourceId)
        this.setSourceData(sourceId, {
            'type': 'LineString',
            'coordinates': []
        })
    }

    /**
     * @param {{}} lngLat an object containing fields: lat and lng
     */
    flyTo(lngLat) {
        // https://docs.mapbox.com/mapbox-gl-js/example/center-on-feature/
        this.map.flyTo({
            center: lngLat
        });
    }

    getIcon(type) {

        // See https://fonts.google.com/icons?selected=Material+Icons&icon.category=maps

        switch (type) {
        case crux_route_course_point_type_e.CONTINUE:
            return "baseline_straight_black_24dp";
        case crux_route_course_point_type_e.SLIGHT_RIGHT:
            return "baseline_turn_slight_right_black_24dp";
        case crux_route_course_point_type_e.RIGHT:
            return "baseline_turn_right_black_24dp";
        case crux_route_course_point_type_e.SHARP_RIGHT:
            return "baseline_u_turn_right_black_24dp";
        case crux_route_course_point_type_e.U_TURN:
            return "baseline_360_black_24dp";
        case crux_route_course_point_type_e.SLIGHT_LEFT:
            return "baseline_turn_slight_left_black_24dp";
        case crux_route_course_point_type_e.LEFT:
            return "baseline_turn_left_black_24dp";
        case crux_route_course_point_type_e.SHARP_LEFT:
            return "baseline_u_turn_left_black_24dp";
        case crux_route_course_point_type_e.DEPART:
            return "baseline_straight_black_24dp";
        case crux_route_course_point_type_e.ARRIVE:
            return "baseline_home_black_24dp";
        case crux_route_course_point_type_e.ROUNDABOUT:
            return "baseline_trip_origin_black_24dp";
        case crux_route_course_point_type_e.WAY_POINT:
            return "baseline_place_black_24dp";
        case crux_route_course_point_type_e.ROUNDABOUT_RIGHT:
            return "baseline_roundabout_right_black_24dp";
        case crux_route_course_point_type_e.ROUNDABOUT_LEFT:
            return "baseline_roundabout_left_black_24dp";
        case crux_route_course_point_type_e.UNKNOWN:
            return "baseline_not_listed_location_black_24dp";
        case crux_route_course_point_type_e.WARNING:
            return "baseline_report_problem_black_24dp";
        case crux_route_course_point_type_e.OTHER:
        case crux_route_course_point_type_e.SUMMIT:
        case crux_route_course_point_type_e.VALLEY:
        case crux_route_course_point_type_e.WATER:
        case crux_route_course_point_type_e.FOOD:
        case crux_route_course_point_type_e.FIRST_AID:
        case crux_route_course_point_type_e.CLIMB_4TH_CAT:
        case crux_route_course_point_type_e.CLIMB_3RD_CAT:
        case crux_route_course_point_type_e.CLIMB_2ND_CAT:
        case crux_route_course_point_type_e.CLIMB_1ST_CAT:
        case crux_route_course_point_type_e.CLIMB_HORS_CAT:
        case crux_route_course_point_type_e.SPRINT:
            return "baseline_place_black_24dp";
        }
    }

    getSourceData(sourceId) {
        let source = this.map.getSource(sourceId);
        return source?._data;
    }

    getSourceDataCoordinates(sourceId) {
        return this.getSourceData(sourceId)?.coordinates;
    }

    hidePopup() {
        this.popup?.remove();
    }

    initRideDirections() {

        let sourceId = "rideDirections";
        let layerId = "rideDirectionsLine";

        // Add the source/layer for the rideDirections line
        this.regEmptySource(sourceId);
        // this.regLayer({
        //     id: "rideDirectionsPts",
        //     type: "circle",
        //     source: "rideDirections",
        //     paint: {
        //         "circle-color": "blue",
        //         "circle-radius": 5,
        //     }
        // });
        this.regLayer({
            id: layerId,
            type: "line",
            source: sourceId,
            paint: {
                "line-color": "blue",
                "line-width": this.getLineWidthWithHover(4, 10),
                "line-opacity": 0.5
            }
        });

        this.registerHoverHandlers(layerId, sourceId);
    }

    /** This is the source/layer for rendering the route we fetch from BoltApp */
    initBoltAppRoute() {

        // Add the source/layer for the boltAppRoute line

        this.regEmptySource(this.ID.BoltAppRoute.Line.sourceId);
        this.regEmptySource(this.ID.BoltAppRoute.StartPt.sourceId);

        this.regLayer({
            id: this.ID.BoltAppRoute.StartPt.layerId,
            type: "circle",
            source: this.ID.BoltAppRoute.StartPt.sourceId,
            paint: {
                "circle-color": "red",
                "circle-radius": 5,
            }
        });

        this.regLayer({
            id: this.ID.BoltAppRoute.Line.layerId,
            type: "line",
            source: this.ID.BoltAppRoute.Line.sourceId,
            paint: {
                "line-color": "red",
                "line-width": this.getLineWidthWithHover(4, 10),
                "line-opacity": 0.5
            }
        });


        this.registerHoverHandlers(this.ID.BoltAppRoute.Line.layerId, this.ID.BoltAppRoute.Line.sourceId);
    }

    /** This is the source/layer for rendering the climbs we fetch from BoltApp. Max 10 supported */
    initBoltAppClimbs() {

        for (let climbIdx = 0; climbIdx < 10; climbIdx++) {

            // Add the source/layer for the boltAppRoute line

            let lineSourceId = this.ID.BoltAppClimb.Line.sourceId + climbIdx;
            let lineLayerId = this.ID.BoltAppClimb.Line.layerId + climbIdx;
            let startPtSourceId = this.ID.BoltAppClimb.StartPt.sourceId + climbIdx;
            let startPtLayerId = this.ID.BoltAppClimb.StartPt.layerId + climbIdx;

            this.regEmptySource(lineSourceId);
            this.regEmptySource(startPtSourceId);

            this.regLayer({
                id: startPtLayerId,
                type: "circle",
                source: startPtSourceId,
                paint: {
                    "circle-color": "magenta",
                    "circle-radius": 5,
                }
            });

            this.regLayer({
                "id": lineLayerId,
                "type": "line",
                "source": lineSourceId,
                "paint": {
                    "line-color": "magenta",
                    "line-width": this.getLineWidthWithHover(4, 10),
                    "line-opacity": 1
                }

            });

            this.registerHoverHandlers(lineLayerId, lineSourceId);
        }

    }

    /** This is the source/layer for rendering the segments we fetch from BoltApp. Max 10 supported */
    initBoltAppSegments() {

        for (let segmentIdx = 0; segmentIdx < 10; segmentIdx++) {

            // Add the source/layer for the boltAppRoute line

            let lineSourceId = this.ID.BoltAppSegment.Line.sourceId + segmentIdx;
            let lineLayerId = this.ID.BoltAppSegment.Line.layerId + segmentIdx;
            let startPtSourceId = this.ID.BoltAppSegment.StartPt.sourceId + segmentIdx;
            let startPtLayerId = this.ID.BoltAppSegment.StartPt.layerId + segmentIdx;

            this.regEmptySource(lineSourceId);
            this.regEmptySource(startPtSourceId);

            this.regLayer({
                id: startPtLayerId,
                type: "circle",
                source: startPtSourceId,
                paint: {
                    "circle-color": "#2494CC",
                    "circle-radius": 5,
                }
            });

            this.regLayer({
                "id": lineLayerId,
                "type": "line",
                "source": lineSourceId,
                "paint": {
                    "line-color": "#2494CC",
                    "line-width": this.getLineWidthWithHover(4, 10),
                    "line-opacity": 1
                }

            });

            //this.registerHoverHandlers(lineLayerId, lineSourceId);
        }

    }

    /** If using this you must also call registerHoverHandlers() in the init function */
    getLineWidthWithHover(lineWidth, lineWidthHover) {
        return [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            lineWidthHover,
            lineWidth
        ];
    }

    registerHoverHandlers(layerId, sourceId, popupTxt = null) {

        // https://docs.mapbox.com/mapbox-gl-js/example/hover-styles/

        this.map.on('mousemove', layerId, (e) => {

            var feature = this.map.querySourceFeatures(sourceId, {
                sourceLayer: layerId,
            })[0];

            if (e.features.length > 0) {

                if (this.hoveredStateId !== null) {
                    // We have a previous hovered object
                    this.map.setFeatureState(
                        {source: this.hoveredSourceId, id: this.hoveredStateId},
                        {hover: false}
                    );
                    this.hidePopup();
                }

                this.hoveredSourceId = sourceId;
                this.hoveredStateId = feature.id;

                this.map.setFeatureState(
                    {source: sourceId, id: this.hoveredStateId},
                    {hover: true}
                );

                if (popupTxt) {
                    this.showPopup(e.lngLat, popupTxt);
                }
            }
        });

        this.map.on('mouseleave', layerId, () => {
            if (this.hoveredStateId !== null) {
                this.map.setFeatureState(
                    {source: this.hoveredSourceId, id: this.hoveredStateId},
                    {hover: false}
                );
                this.hidePopup();
            }
            this.hoveredSourceId = null;
            this.hoveredStateId = null;
        });
    }

    hoveredSourceId = null;
    hoveredStateId = null;

    initRideWaypoints() {
        // Add the source/layer for the rideWaypoints (from user-entered points)
        this.regEmptySource("rideWaypoints");
        this.regLayer({
            id: "rideWaypointsPts",
            type: "circle",
            source: "rideWaypoints",
            paint: {
                "circle-color": "blue",
                "circle-radius": 10,
            }
        });
    }

    initRouteClimbs() {

        let sourceId = "routeClimbs";
        let layerId = "routeClimbsLine";

        // Add the source/layer for the routeClimbs line (from mapbox)
        this.regEmptySource(sourceId);
        this.regEmptySource("routeClimbInfos");


        this.regLayer({
            id: layerId,
            type: "line",
            source: sourceId,
            paint: {
                "line-color": "magenta",
                "line-width": this.getLineWidthWithHover(4, 10),
                "line-opacity": 0.5
            }
        });

        this.registerHoverHandlers(layerId, sourceId);

        this.regLayer({
            id: "routeClimbsPts",
            type: "circle",
            source: sourceId,
            paint: {
                "circle-color": "magenta",
                "circle-radius": 5,
            }
        });


        // This layer is invisible/transparent large circles which makes the climb info popup work nicer
        // The mouse-enter/mouse-leave events are really buggy over routeClimbsIcons with type= symbol
        this.regLayer({
            id: "routeClimbsPopupAreas",
            type: "circle",
            source: "routeClimbInfos",
            paint: {
                "circle-color": "transparent",
                "circle-radius": 10,
            }
        });

        // Icon properties https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#layout-symbol-icon-size
        this.regLayer({
            "id": "routeClimbsIcons",
            "type": "symbol",
            "source": "routeClimbInfos",
            "layout": {
                "icon-size": 0.7,
                "icon-image": "baseline_terrain_black_24dp",
                'icon-ignore-placement': true,

            }
        });


        // Popup for directions
        // https://docs.mapbox.com/mapbox-gl-js/example/popup-on-hover/

        // Create a popup, but don't add it to the map yet.

        this.setOnMouseEnterListener('routeClimbsPopupAreas', (event) => {
            this.showPopup(event.lngLat, event.features[0].properties.description);
        });

        this.setOnMouseLeaveListener('routeClimbsPopupAreas', (event) => {
            this.hidePopup();
        });
    }

    initRouteClosestPoint() {
        // Add the source/layer for the closestPt (from closestPt-entered points)
        this.regEmptySource(this.ID.Route.ClosestPt.sourceId);
        this.regLayer({
            id: this.ID.Route.ClosestPt.layerId,
            type: "circle",
            source: this.ID.Route.ClosestPt.sourceId,
            paint: {
                "circle-color": "green",
                "circle-radius": 10,
            }
        });
    }

    initRouteCoursePoints() {

        // Add the source/layer for the routeCoursePoints line points (from mapbox)
        this.regEmptySource(this.ID.Route.CoursePts.Pts.sourceId);

        // Add the source/layer for the routeCoursePoints 20m radius circles (from mapbox)
        this.regEmptySource(this.ID.Route.CoursePts.Circles.sourceId);

        // This layer is invisible/transparent large circles which makes the course point popup work nicer
        // The mouse-enter/mouse-leave events are really buggy over routeCoursePointsIcons with type= symbol
        this.regLayer({
            id: this.ID.Route.CoursePts.Pts.PopupArea.layerId,
            type: "circle",
            source: this.ID.Route.CoursePts.Pts.sourceId,
            paint: {
                "circle-color": "transparent",
                "circle-radius": 10,
            }
        });


        // This layer is the circle
        // https://ogeek.cn/qa/?qa=774045/
        this.regLayer(
            {
                "id": this.ID.Route.CoursePts.Circles.Circle.layerId,
                "type": "fill",
                "source": this.ID.Route.CoursePts.Circles.sourceId,
                "layout": {},
                "paint": {
                    "fill-color": "red",
                    "fill-opacity": 0.25
                }
            });

        // Icon properties https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#layout-symbol-icon-size
        this.regLayer({
            "id": this.ID.Route.CoursePts.Pts.Icon.layerId,
            "type": "symbol",
            "source": this.ID.Route.CoursePts.Pts.sourceId,
            "layout": {
                "icon-size": 0.7,
                "icon-image": ["get", "icon-image"],
                "icon-rotate": ["get", "icon-rotate"],
                "icon-rotation-alignment": "map", // This allows us to rotate the icons to align with the route line https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#layout-symbol-icon-rotation-alignment
                'icon-ignore-placement': true,
            }
        });


        // Popup for directions
        // https://docs.mapbox.com/mapbox-gl-js/example/popup-on-hover/

        // Create a popup, but don't add it to the map yet.

        this.setOnMouseEnterListener(this.ID.Route.CoursePts.Pts.PopupArea.layerId, (event) => {
            //console.log("<< OnMouseEnterListener routeCoursePointsIcons")
            this.showPopup(event.lngLat, event.features[0].properties.description);
        });

        this.setOnMouseLeaveListener(this.ID.Route.CoursePts.Pts.PopupArea.layerId, (event) => {
            // console.log("<< OnMouseLeaveListener routeCoursePointsIcons")
            this.hidePopup();
        });
    }

    initRouteDirections() {


        // Add the source/layer for the routeDirections line (from mapbox)
        this.regEmptySource(this.ID.Route.Directions.sourceId);

        this.regLayer({
            id: this.ID.Route.Directions.Line.layerId,
            type: "line",
            source: this.ID.Route.Directions.sourceId,
            paint: {
                "line-color": "red",
                "line-width": this.getLineWidthWithHover(4, 10),
                "line-opacity": 0.5
            }
        });
        this.registerHoverHandlers(this.ID.Route.Directions.Line.layerId, this.ID.Route.Directions.sourceId);

        this.regLayer({
            id: this.ID.Route.Directions.Pts.layerId,
            type: "circle",
            source: this.ID.Route.Directions.sourceId,
            paint: {
                "circle-color": "red",
                "circle-radius": 5,
            }
        });
    }

    initRouteOnOffPoints() {
        this.regEmptySource("routeNavigatorOnRouteCoords");
        this.regLayer({
            id: "routeNavigatorOnRouteCoordsPts",
            type: "circle",
            source: "routeNavigatorOnRouteCoords",
            paint: {
                "circle-color": "green",
                "circle-radius": 4,
            }
        });

        this.regEmptySource("routeNavigatorOffRouteCoords");
        this.regLayer({
            id: "routeNavigatorOffRouteCoordsPts",
            type: "circle",
            source: "routeNavigatorOffRouteCoords",
            paint: {
                "circle-color": "orange",
                "circle-radius": 4,
            }
        });
    }

    initRouteWaypoints() {

        // Add the source/layer for the routeWaypoints (from user-entered points)
        this.regEmptySource("routeWaypoints");
        this.regLayer({
            id: "routeWaypointsPts",
            type: "circle",
            source: "routeWaypoints",
            paint: {
                "circle-color": "red",
                "circle-radius": 10,
            }
        });
    }

    initUserPoint() {
        // Add the source/layer for the user (from user-entered points)

        let sourceId = "user";
        let layerId = "userPt";

        this.regEmptySource(sourceId);
        this.regLayer({
            id: layerId,
            type: "circle",
            source: sourceId,
            paint: {
                "circle-color": "green",
                "circle-radius": 10,
            }
        });
    }

    initBoltAppUserPoint() {

        let sourceId = "boltAppUser";
        let layerId = "boltAppUserPt";

        // Add the source/layer for the user (from user-entered points)
        this.regEmptySource(sourceId);

        this.regLayer({
            id: layerId,
            type: "circle",
            source: sourceId,
            paint: {
                "circle-color": "grey",
                "circle-radius": 10,
            }
        });
    }

    /**
     * Registers an empty source (of type geojson) with the map if not already registered.
     * If already registered, this function does nothing.
     * */
    regEmptySource(sourceId) {
        this.regSource(sourceId, {
            "type": "geojson",
            "tolerance": 0.000001,
            "generateId": true, // This ensures that all features have unique IDs - needed for hover
            "data": {
                'type': 'Feature',
                'properties': {}
            }
        })
    }

    regLayer(layer) {
        if (!this.map.getLayer(layer.id)) {
            this.map.addLayer(layer);
        }
    }

    /**
     * Registers a source with the map if not already registered.
     * If already registered, this function does nothing.
     * */
    regSource(sourceId, source) {
        if (!this.map.getSource(sourceId)) {
            this.map.addSource(sourceId, source);
        }
    }

    refreshLayerVisibility() {

        const map = this.map;

        let showMapLabelsStr = localStorage.getItem("showMapLabels");
        let showMapLabels = showMapLabelsStr === "true";

        console.log("refreshLayerVisibility showMapLabels", showMapLabelsStr, showMapLabels)

        // See https://stackoverflow.com/a/43853458/5845266
        map?.style?.stylesheet?.layers?.forEach(function (layer) {

            let remove = false;

            if (layer.id.includes("-label")) {
                remove = !showMapLabels;
            } else if (layer.type === 'symbol') {
                remove = true;
            }

            if (remove) {
                //console.log("refreshLayerVisibility removing", layer.id)
                map.setLayoutProperty(layer.id, 'visibility', 'none');
            } else {
                //console.log("refreshLayerVisibility keeping", layer.id)
                map.setLayoutProperty(layer.id, 'visibility', 'visible');
            }
        });
    }

    resetCursor() {
        console.log("resetCursor")
        this.map.getCanvas().style.cursor = ''
    }

    setCursorAuto() {
        console.log("setCursorAuto")
        this.map.getCanvas().style.cursor = 'auto'
    }

    setCursorCrossHair() {
        console.log("setCursorCrossHair")
        this.map.getCanvas().style.cursor = 'crosshair'
    }

    setCursorPointer() {
        console.log("setCursorPointer")
        this.map.getCanvas().style.cursor = 'pointer'
    }

    setOnMouseEnterListener(layerId, callback) {
        // See https://docs.mapbox.com/mapbox-gl-js/api/events/#mapmouseevent
        // Type ("mousedown" | "mouseup" | "preclick" | "click" | "dblclick" | "mousemove" | "mouseover" | "mouseenter" | "mouseleave" | "mouseout" | "contextmenu")
        this.map.on('mouseenter', layerId, (event) => {
            callback(event);
        });
    }

    setOnMouseLeaveListener(layerId, callback) {
        // See https://docs.mapbox.com/mapbox-gl-js/api/events/#mapmouseevent
        // Type ("mousedown" | "mouseup" | "preclick" | "click" | "dblclick" | "mousemove" | "mouseover" | "mouseenter" | "mouseleave" | "mouseout" | "contextmenu")
        this.map.on('mouseleave', layerId, (event) => {
            callback(event);
        });
    }

    setOnMouseMoveListener(layerId, callback) {
        // See https://docs.mapbox.com/mapbox-gl-js/api/events/#mapmouseevent
        // Type ("mousedown" | "mouseup" | "preclick" | "click" | "dblclick" | "mousemove" | "mouseover" | "mouseenter" | "mouseleave" | "mouseout" | "contextmenu")
        this.map.on('mousemove', layerId, (event) => {
            callback(event);
        });
    }

    setRideDirectionsFromCruxRoute(route) {
        if (route)
            this.setSourceDataLineStringFromCruxRoute("rideDirections", route);
        else
            this.clearRideDirections()
    }

    setBoltAppRouteFromCoords(coords) {

        //console.log("setBoltAppRouteFromCoords coords=" + coords?.length)

        if (coords && coords.length > 0) {
            this.setSourceDataLineStringFromCoords(this.ID.BoltAppRoute.Line.sourceId, coords);
            this.setSourceDataMultiPointFromCoordinates(this.ID.BoltAppRoute.StartPt.sourceId, [coords[0]]);

        } else {
            this.clearSourceData(this.ID.BoltAppRoute.Line.sourceId)
            this.clearSourceData(this.ID.BoltAppRoute.StartPt.sourceId)
        }
    }

    setBoltAppClimbFromCoords(climbIdx, coords, name) {
        //console.log("setBoltAppClimbFromCoords climbIdx=" + climbIdx, "coords=" + coords?.length)

        let lineSourceId = this.ID.BoltAppClimb.Line.sourceId + climbIdx;
        let lineLayerId = this.ID.BoltAppClimb.Line.layerId + climbIdx;
        let startPtSourceId = this.ID.BoltAppClimb.StartPt.sourceId + climbIdx;

        if (coords && coords.length > 0) {
            this.setSourceDataLineStringFromCoords(lineSourceId, coords);
            this.setSourceDataMultiPointFromCoordinates(startPtSourceId, [coords[0]]);

            this.registerHoverHandlers(lineLayerId, lineSourceId, name); // We call this here because its custom for the segment
        } else {
            this.clearSourceData(lineSourceId)
            this.clearSourceData(startPtSourceId)
        }
    }

    setBoltAppSegmentFromCoords(segmentIdx, coords, segmentName) {
        //console.log("setBoltAppSegmentFromCoords segmentIdx=" + segmentIdx, "coords=" + coords?.length)

        let lineSourceId = this.ID.BoltAppSegment.Line.sourceId + segmentIdx;
        let lineLayerId = this.ID.BoltAppSegment.Line.layerId + segmentIdx;
        let startPtSourceId = this.ID.BoltAppSegment.StartPt.sourceId + segmentIdx;

        if (coords && coords.length > 0) {
            this.setSourceDataLineStringFromCoords(lineSourceId, coords);
            this.setSourceDataMultiPointFromCoordinates(startPtSourceId, [coords[0]]);

            this.registerHoverHandlers(lineLayerId, lineSourceId, segmentName); // We call this here because its custom for the segment
        } else {
            this.clearSourceData(lineSourceId)
            this.clearSourceData(startPtSourceId)
        }
    }

    setRideWaypoints(rideWaypoints) {

        // For the ride we only want the first and last points
        // MultiPoint is good for the waypoints because we need to support 1 coordinate and no need for lines

        let rideWaypointsLen = rideWaypoints.length;
        if (rideWaypointsLen > 2) {
            let firstAndLast = [rideWaypoints[0], rideWaypoints[rideWaypointsLen - 1]];
            this.setSourceDataMultiPointFromLngLats("rideWaypoints", firstAndLast);
        } else {
            this.setSourceDataMultiPointFromLngLats("rideWaypoints", rideWaypoints);
        }
    }

    setRouteClosestPoint(closestPoint) {
        if (closestPoint) {
            let closestPointCoordinate = [closestPoint.lon_deg, closestPoint.lat_deg];
            this.setSourceDataMultiPointFromCoordinates(this.ID.Route.ClosestPt.sourceId, [closestPointCoordinate]);
        } else {
            this.clearSourceData(this.ID.Route.ClosestPt.sourceId)
        }
    }

    /**
     * Sets a FeatureCollection source containing course points source from a CruxRoute.
     */
    setRouteCoursePointsFromCruxRoute(cruxRoute) {

        // routeCoursePoints
        let routeCoursePointsData = {
            'type': 'FeatureCollection',
            'features': []
        };

        // routeCoursePointCircles
        let routeCoursePointCirclesData = {
            'type': 'FeatureCollection',
            'features': []
        };

        for (let i = 0; i < cruxRoute.getCoursePointCount(); i++) {
            let coursePoint = cruxRoute.getCoursePoint(i)

            // Standard icons can be found here
            // https://github.com/mapbox/mapbox-gl-styles
            // See https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#layout-symbol-icon-rotate

            // routeCoursePoints
            routeCoursePointsData.features.push({
                'type': 'Feature',
                'properties': {
                    'description': coursePoint.instruction ? coursePoint.instruction : coursePoint.street_name,
                    'icon-image': this.getIcon(coursePoint.type),
                    'icon-rotate': 0,
                    'icon-ignore-placement': false,
                },
                'geometry': {
                    'type': 'Point',
                    'coordinates': [coursePoint.lon_deg, coursePoint.lat_deg]
                }
            });

            //  routeCoursePointCircles
            let data = this.#getCoursePointCircleData(coursePoint.lat_deg, coursePoint.lon_deg, coursePoint.instruction, coursePoint.type, 0);
            routeCoursePointCirclesData.features.push(data);

        }

        // routeCoursePoints
        this.regEmptySource(this.ID.Route.CoursePts.Pts.sourceId);
        this.map.getSource(this.ID.Route.CoursePts.Pts.sourceId).setData(routeCoursePointsData);

        // routeCoursePointCircles
        this.regEmptySource(this.ID.Route.CoursePts.Circles.sourceId);
        this.map.getSource(this.ID.Route.CoursePts.Circles.sourceId).setData(routeCoursePointCirclesData);
    }

    setRouteCoursePointsFromMapboxRoute(cruxJs, mapboxRoute) {

        let route_common = new crux_route_common(cruxJs);

        // routeCoursePoints
        let routeCoursePointsData = {
            'type': 'FeatureCollection',
            'features': []
        };

        // routeCoursePointCircles
        let routeCoursePointCirclesData = {
            'type': 'FeatureCollection',
            'features': []
        };

        mapboxRoute.legs.forEach((leg, idx, legs) => {

            let lastLeg = idx === legs.length - 1;

            leg.steps.forEach((step, idx, steps) => {

                let lastStep = idx === steps.length - 1;
                let arriveAllowed = lastLeg && lastStep;
                let type = route_common.course_point_type_from_mapbox_maneuver(step.maneuver.type, step.maneuver.modifier);
                if (type === crux_route_course_point_type_e.ARRIVE && !arriveAllowed) {
                    // When you build a mapbox route with lots of sections on the UI - bit by bit
                    // I noticed you get several 'you have arrives at your destination' course points along the route
                    // This check only allows the last ARRIVE to exist
                    return;
                }


                let lat_deg = step.maneuver.location[1];
                let lon_deg = step.maneuver.location[0];
                //let distance_m = step.distance;
                let instruction = step.maneuver.instruction;
                //let street_name = step.name;
                //let exit_name = step.maneuver.exit;
                let bearing = type === crux_route_course_point_type_e.DEPART ? step.maneuver.bearing_after : step.maneuver.bearing_before;

                // routeCoursePoints
                routeCoursePointsData.features.push({
                    'type': 'Feature',
                    'properties': {
                        'description': instruction,
                        'icon-image': this.getIcon(type),
                        'icon-rotate': bearing,
                        'icon-ignore-placement': false,
                    },
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [lon_deg, lat_deg]
                    }
                });

                //  routeCoursePointCircles
                let data = this.#getCoursePointCircleData(lat_deg, lon_deg, instruction, type, bearing);
                routeCoursePointCirclesData.features.push(data);

            });
        });

        // routeCoursePoints
        this.regEmptySource(this.ID.Route.CoursePts.Pts.sourceId);
        this.map.getSource(this.ID.Route.CoursePts.Pts.sourceId).setData(routeCoursePointsData);

        // routeCoursePointCircles
        this.regEmptySource(this.ID.Route.CoursePts.Circles.sourceId);
        this.map.getSource(this.ID.Route.CoursePts.Circles.sourceId).setData(routeCoursePointCirclesData);

    }

    /**
     * @param {CruxRoute} cruxRoute
     */
    setRouteDirectionsFromCruxRoute(cruxRoute) {
        this.setSourceDataLineStringFromCruxRoute(this.ID.Route.Directions.sourceId, cruxRoute);
    }

    /**
     * @param {[]} routeWaypoints array of object containing fields: lng, lat
     */
    setRouteWaypoints(routeWaypoints) {
        // MultiPoint is good for the route waypoints because we show something even if we have 1 coordinate and no need for lines

        // For the ride we only want the first and last points

        let routeWaypointsLen = routeWaypoints.length;
        if (routeWaypointsLen > 2) {
            let firstAndLast = [routeWaypoints[0], routeWaypoints[routeWaypointsLen - 1]];
            this.setSourceDataMultiPointFromLngLats("routeWaypoints", firstAndLast);
        } else {
            this.setSourceDataMultiPointFromLngLats("routeWaypoints", routeWaypoints);
        }


    }

    /**
     * Sets the data for the specified source.
     * This function will register the source (of type geojson) with the map if not already registered.
     * */
    setSourceData(sourceId, data) {
        this.regEmptySource(sourceId);
        this.map.getSource(sourceId).setData(data);
    }

    /**
     * Sets a LineString source from a CruxRoute.
     * 'LineString' sources are rendered with a layer of type 'circle' and/or 'line', but only if you have 2 points in the source
     */
    setSourceDataLineStringFromCruxRoute(sourceId, cruxRoute) {
        // Convert to coordinates
        let coordinates = [];

        for (let i = 0; i < cruxRoute.getLocationPointCount(); i++) {
            let locationPoint = cruxRoute.getLocationPoint(i)
            let coordinate = [locationPoint.lon_deg, locationPoint.lat_deg];
            coordinates.push(coordinate)
        }

        this.setSourceDataLineStringFromCoords(sourceId, coordinates);
    }

    /**
     * Sets a LineString source from list of coordinates.<br/>
     * [[lon0, lat0], [lon1, lat1], ..., [lonN, latN]].<br/>
     * 'LineString' sources are rendered with a layer of type 'circle' and/or 'line', but only if you have 2 points in the source.<br/>
     */
    setSourceDataLineStringFromCoords(sourceId, coordinates) {
        console.log("setSourceDataLineStringFromCoords sourceId=" + sourceId, "coordinates=" + coordinates?.length)
        this.regEmptySource(sourceId);
        this.map.getSource(sourceId).setData({
            'type': 'LineString',
            'coordinates': coordinates
        });
    }

    /**
     * Sets a MultiPoint source from an array of MapBox coordinates.
     * 'MultiPoint' sources are rendered with a layer of type 'circle'.
     * WARNING: 'MultiPoint' sources are will not render with a layer of type 'line'.
     */
    setSourceDataMultiPointFromCoordinates(sourceId, coordinates) {
        this.regEmptySource(sourceId);
        this.map.getSource(sourceId).setData({
            'type': 'MultiPoint',
            'coordinates': coordinates
        });
    }

    /**
     * Sets a MultiPoint source from an array of MapBox LngLats.
     * 'MultiPoint' sources are rendered with a layer of type 'circle'.
     * WARNING: 'MultiPoint' sources are will not render with a layer of type 'line'.
     * @param sourceId the source ID
     * @param {[]} lngLats array of object containing fields: lng, lat
     */
    setSourceDataMultiPointFromLngLats(sourceId, lngLats) {
        // Convert to coordinates
        let coordinates = [];
        lngLats.forEach(lngLat => {
            let coordinate = [lngLat.lng, lngLat.lat];
            coordinates.push(coordinate)
        });
        this.setSourceDataMultiPointFromCoordinates(sourceId, coordinates);
    }

    setUserLngLat(userLngLat) {
        this.setSourceDataMultiPointFromLngLats("user", [userLngLat]);
    }

    setBoltAppUserLngLat(boltAppUserLngLat) {
        if (boltAppUserLngLat)
            this.setSourceDataMultiPointFromLngLats("boltAppUser", [boltAppUserLngLat]);
        else
            this.clearSourceData("boltAppUser")
    }

    showPopup(lngLat, html) {

        // https://docs.mapbox.com/mapbox-gl-js/example/popup-on-hover/

        // Only one popup allowed at a time
        this.hidePopup();

        this.popup = new Popup({
            closeButton: false,
            closeOnClick: false
        });
        this.popup.setLngLat(lngLat).setHTML(html).addTo(this.map);
    }
}
