/* eslint-disable react-hooks/exhaustive-deps */
/* global BigInt */
/* eslint-disable-next-line import/no-webpack-loader-syntax */
import mapboxgl from '!mapbox-gl';
import useState from 'react-usestateref'
import React, {useEffect, useRef} from 'react';
import {crux_route_navigator_state_e, CruxJs, CruxRoute, CruxRouteNavigator, CruxRoutePlayer} from '@WahooFitness/crux-packed/dist/index';
import {MapBoxApi} from "../utils/MapBoxApi";
import {MapView} from "./MapView";
import {useInterval} from "./UseInterval";
import MyFileInput from "./MyFileInput";
import {Badge, Modal, Toast, ToastContainer} from "react-bootstrap";
import {BoltAppApi} from "../utils/BoltAppApi";
import {DownloadRouteOrRideModal} from "./DownloadRouteOrRideModal";
import {SettingsModal} from "./SettingsModal";
import {Chart} from "react-google-charts";
import {GoogleChartsUtils} from "../utils/GoogleChartsUtils";
import {FaCog, FaCompressArrowsAlt, FaCopy, FaDownload, FaFastForward, FaMapPin, FaMountain, FaPlay, FaRoute, FaTrash, FaUndo} from "react-icons/fa";
import MyButton from "./MyButton";
import {ClimbDetector} from "../utils/ClimbDetector";
import {ImRoad} from "react-icons/im";
import {GiBroadheadArrow} from "react-icons/gi";
import {BsFillSkipStartFill} from "react-icons/bs";
import {MdSendToMobile} from "react-icons/md";
import {BiShow} from "react-icons/bi";
import {GoogleElevationApi} from "../utils/GoogleElevationApi";

mapboxgl.accessToken = 'pk.eyJ1Ijoid2Fob29maXRuZXNzIiwiYSI6ImNqMXFvZ3dydTAwaTQzNXZlcWJlNjdoZnQifQ.3KR1FPpntw4u4EUF9bAQKQ';


/**
 * https://docs.mapbox.com/help/tutorials/use-mapbox-gl-js-with-react/#final-product
 * @returns {JSX.Element}
 * @constructor
 */
export default function MapTab({cruxJs}) {


    const mapContainer = useRef(null);
    const map = useRef(null);

    const [statusTxt, setStatusTxt] = useState("");
    const [mapLastClickLngLat, setMapLastClickLngLat] = useState(null);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [mapUiMode, setMapUiMode] = useState(0);
    const [rideDirections, setRideDirections] = useState(null);
    const [rideIsPlaying, setRideIsPlaying] = useState(false);
    const [ridePlayer, setRidePlayer] = useState(null);
    const [rideUserSpeedKph, setRideUserSpeedKph] = useState(20);
    const [rideWaypoints, setRideWaypoints] = useState([]);
    const [rideFreepoints, setRideFreepoints] = useState([]);
    const [routeDirections, setRouteDirections] = useState(null);
    const [routeNavigator, setRouteNavigator, routeNavigatorRef] = useState(null);
    const [routeNavigatorOnRouteCoords, setRouteNavigatorOnRouteCoords] = useState([]);
    const [routeNavigatorOffRouteCoords, setRouteNavigatorOffRouteCoords] = useState([]);
    const [routeWaypoints, setRouteWaypoints] = useState([]);
    const [upTimeMs, setUpTimeMs] = useState(0);
    const [userLngLat, setUserLngLat] = useState(null); // Object with fields lat, lng, and optional elevM
    const [showDownloadRoute, setShowDownloadRoute] = useState(false);
    const [showBoltAppApiCfg, setShowBoltAppApiCfg] = useState(false);
    const [onOffRouteColor, setOnOffRouteColor] = useState("grey");
    const [nextCoursePointToastText, setNextCoursePointToastText] = useState("");
    const [showWelcome, setShowWelcome] = useState(true);
    const [elevData, setElevData] = useState(GoogleChartsUtils.EMPTY);
    const [autoSendUserToBoltApp, setAutoSendUserToBoltApp] = useState(false);
    const [isBuildingMapboxRoute, setBuildingMapboxRoute] = useState(false);

    // https://docs.mapbox.com/mapbox-gl-js/example/center-on-feature/

    /**
     * Called when the routeWaypoints changes
     */
    useEffect(() => {

        function onRouteWaypointsChanged() {
            if (!mapLoaded) return;
            if (!routeWaypoints) return;

            // routeWaypoints is an array of objects possessing a lng and lat field

            console.log("<< [mapLoaded, routeWaypoints]", routeWaypoints.length)

            map.current.setRouteWaypoints(routeWaypoints);

            if (routeWaypoints.length >= 2) {

                console.log(">> MapBoxApi fetchDirectionsAndElevations")
                setBuildingMapboxRoute(true)
                MapBoxApi.fetchDirectionsAndElevations(routeWaypoints, function onComplete(mapboxDirections) {
                    console.log("<< MapBoxApi fetchDirectionsAndElevations", mapboxDirections ? "OK" : "FAILED")
                    setRouteDirections(mapboxDirections);
                    setBuildingMapboxRoute(false)

                });
            } else {
                setRouteDirections(null);
            }
        }

        onRouteWaypointsChanged();
    }, [routeWaypoints])

    /**
     * Called when the rideWaypoints changes - as use clicks the map, building their ride.
     * rideWaypoints are rendered to the plot as circles.
     * They are sent to MapBox and we get a rideDirections back (as a mapbox JSON) - which follows the roads.
     * These directions are converted to crux route
     */
    useEffect(() => {
        function onRideWaypointsChanged() {

            if (!mapLoaded) return;

            if (!rideWaypoints) return;

            console.log("<< [rideWaypoints]", rideWaypoints.length)

            // MultiPoint is good for the waypoints because we need to support 1 coordinate and no need for lines
            map.current.setRideWaypoints(rideWaypoints)

            if (rideWaypoints.length >= 2) {
                setBuildingMapboxRoute(true)
                MapBoxApi.fetchDirectionsAndElevations(rideWaypoints, mapboxDirections => {
                    // I don't know why we need to do this?
                    if (rideDirections instanceof CruxRoute) {
                        rideDirections.destroy();
                    }
                    setRideDirections(mapboxDirections);
                    setBuildingMapboxRoute(false)
                });
            } else {
                // I don't know why we need to do this?
                if (rideDirections instanceof CruxRoute) {
                    rideDirections.destroy();
                }
                setRideDirections(null);
            }
        }

        onRideWaypointsChanged();
    }, [mapLoaded, rideWaypoints])

    /**
     * Called when the rideFreepoints changes - as use clicks the map, building their ride.
     * rideFreepoints are rendered to the plot as circles.
     * They are NOT sent to MapBox, rather we pumpt them directly into the CruxRoute.
     * This data flow is identical to rideWaypoints, but we just skip to 'convert to road directs step'
     */
    useEffect(() => {
        function onRideFreepointsChanged() {

            if (!mapLoaded) return;

            if (!rideFreepoints) return;

            console.log("<< [rideFreepoints]", rideFreepoints.length)

            // MultiPoint is good for the freepoints because we need to support 1 coordinate and no need for lines
            map.current.setRideWaypoints(rideFreepoints)

            // We convert teh rideFreepoints to a mapboxDirections ourselves here directly without going through the MapBoX API
            let mapboxDirections = {
                routes: [{
                    geometry: {
                        coordinates: []
                    },
                    legs: [],
                    distance: -1
                }]
            };
            let coordinates = mapboxDirections.routes[0].geometry.coordinates;
            for (let i = 0; i < rideFreepoints.length; i++) {
                coordinates.push([rideFreepoints[i].lng, rideFreepoints[i].lat]);
            }


            if (rideFreepoints.length >= 2) {
                setBuildingMapboxRoute(true)
                GoogleElevationApi.fetchElevations(mapboxDirections, mapboxDirections => {
                    // I don't know why we need to do this?
                    if (rideDirections instanceof CruxRoute) {
                        rideDirections.destroy();
                    }
                    setRideDirections(mapboxDirections);
                    setBuildingMapboxRoute(false)
                });
            } else {
                // I don't know why we need to do this?
                if (rideDirections instanceof CruxRoute) {
                    rideDirections.destroy();
                }
                setRideDirections(null);
            }
        }

        onRideFreepointsChanged();
    }, [mapLoaded, rideFreepoints])

    /**
     * Called when the routeDirections changes.
     * We update the routeDirections in the map.
     */
    useEffect(() => {
        function onRouteDirectionsChanged() {

            if (!cruxJs) return;

            console.log("<< [routeDirections]", routeDirections != null)

            let navigator = null;

            if (routeDirections) {

                let cruxRoute;
                let mapboxRoute;
                if (routeDirections instanceof CruxRoute) {
                    cruxRoute = routeDirections;
                    mapboxRoute = null;
                } else {
                    cruxRoute = null;
                    mapboxRoute = routeDirections.routes[0];
                }

                if (!cruxRoute) {
                    cruxRoute = new CruxRoute(cruxJs);
                    cruxRoute.buildFromMapboxRoute(mapboxRoute);
                    cruxRoute.calculateClimbs();
                    console.log("climbCount=", cruxRoute.getClimbCount())
                }


                map.current.setRouteDirectionsFromCruxRoute(cruxRoute);
                map.current.addRouteClimbsFromCruxRoute(cruxRoute, true);

                // We can totally render the course points from the FIT file, and it's a good test to do this...
                // But since the route does not easily give us the bearing at the CP, we miss out on rotating our icons to align with the map, which look really cool
                // Instead, we render the CPs using the MapBox route, which has the bearing info, allowing us to rotate the icon to align nicely with the map
                if (mapboxRoute) {
                    map.current.setRouteCoursePointsFromMapboxRoute(cruxJs, mapboxRoute);
                } else {
                    map.current.setRouteCoursePointsFromCruxRoute(cruxRoute);
                }


                // The existing navigator is destroyed later
                navigator = new CruxRouteNavigator(cruxRoute, true);
                navigator.setCallbackNavigatorStateChanged(onRouteNavigatorStateChangedFromCrux);
                navigator.setCallbackNavigatorLocationProcessed(onRouteNavigatorLocationProcessedFromCrux);
                navigator.setCallbackNavigatorCoursePointImminent(onRouteNavigatorCoursePointImminentFromCrux);

                let elevData1 = GoogleChartsUtils.getElevData(cruxRoute);
                console.log("elevData=" + elevData1.length, elevData1[0])
                setElevData(elevData1);


            } else {
                map?.current?.clearRoute()
                setRouteNavigatorOnRouteCoords([])
                setRouteNavigatorOffRouteCoords([])
                setOnOffRouteColor("grey",);
                setElevData(GoogleChartsUtils.EMPTY)
            }

            // Its possible navigator changed, set it
            routeNavigator?.destroy();
            setRouteNavigator(navigator);

        }

        onRouteDirectionsChanged();
    }, [routeDirections])

    /**
     * Called when the routeNavigator changes which is a good handle for when the CruxRoute changes
     */
    useEffect(() => {

        function onCruxRouteChanged() {

            console.log("<< [routeNavigator]")


        }

        onCruxRouteChanged();
    }, [routeNavigator])

    /**
     * Called when the rideDirections changes.
     */
    useEffect(() => {
        function onRideDirectionsChanged() {


            if (!cruxJs) return;

            console.log("<< [rideDirections]", rideDirections != null)

            ridePlayer?.destroy();

            if (!rideDirections) {
                map?.current?.setRideDirectionsFromCruxRoute(null);
                setRidePlayer(null);
                setRouteNavigatorOnRouteCoords([])
                setRouteNavigatorOffRouteCoords([])
                return;
            }

            let cruxRoute;

            if (rideDirections instanceof CruxRoute) {
                cruxRoute = rideDirections;
            } else {
                let mapboxRoute = rideDirections.routes[0];
                cruxRoute = new CruxRoute(cruxJs);
                cruxRoute.buildFromMapboxRoute(mapboxRoute);
            }

            let newRidePlayer = new CruxRoutePlayer(cruxRoute, true);

            setRidePlayer(newRidePlayer);
            newRidePlayer.setSpeedMps(rideUserSpeedKph * 0.277778);

            map.current.setRideDirectionsFromCruxRoute(cruxRoute);

        }

        onRideDirectionsChanged();
    }, [rideDirections])

    /**
     * Called when the mapLoaded changes.
     * We initialise our map sources and layers.
     */
    useEffect(() => {
        function onMapLoaded() {

            let _map = map.current;

            _map.refreshLayerVisibility()

            // Init the routeDirections. We set the data later.
            // https://developers.arcgis.com/mapbox-gl-js/route-and-directions/find-a-route-and-directions/

            // User
            _map.initUserPoint()

            // Route
            _map.initRouteClosestPoint()
            _map.initRouteWaypoints()
            _map.initRouteDirections()
            _map.initRouteClimbs()
            _map.initRouteCoursePoints()
            _map.initRouteOnOffPoints()

            // Ride
            _map.initRideWaypoints()
            _map.initRideDirections()

            // BoltApp
            _map.initBoltAppUserPoint()
            _map.initBoltAppRoute()
            _map.initBoltAppClimbs()
            _map.initBoltAppSegments()

        }

        if (!mapLoaded) return;
        onMapLoaded();
    }, [mapLoaded])

    /**
     * Called when the mapLastClickLngLat changes
     */
    useEffect(() => {
        function onMapLastClickLngLatChanged() {

            if (!mapLastClickLngLat) return;

            console.log("<< [mapLastClickLngLat]", mapLastClickLngLat)

            if (mapUiMode === 0) {
                map?.current?.flyTo(mapLastClickLngLat)
            } else if (mapUiMode === 2) {
                setRouteWaypoints([...routeWaypoints, mapLastClickLngLat])
            } else if (mapUiMode === 3) {
                setRideWaypoints([...rideWaypoints, mapLastClickLngLat])
            } else if (mapUiMode === 5) {
                setRideFreepoints([...rideFreepoints, mapLastClickLngLat])
            } else if (mapUiMode === 4) {
                setUserLngLat(mapLastClickLngLat)
            }


        }

        onMapLastClickLngLatChanged();
    }, [mapLastClickLngLat])


    /**
     * Called when the userLngLat changes
     */
    useEffect(() => {
        function onUserLngLatChanged() {

            if (!userLngLat) return;

            // Every time the user location changes its 1sec simulation time passed
            setUpTimeMs(upTimeMs + 1000);

            // Plot the user location
            map.current.setUserLngLat(userLngLat)

            // Update the routeNavigator
            if (routeNavigator) {
                routeNavigator.setUserLocationFromLngLat(BigInt(upTimeMs), userLngLat);
                // Go to the onRouteNavigatorXYZ() functions to see what happens next

                // This log line helps us copy stuff from the Chrome console and add it to a unit test in crux_route_navigator_set_user_location_test
                console.log("SET_USER_LOCATION(" + userLngLat.lat + "," + userLngLat.lng + ",0);")
            }


            if (autoSendUserToBoltApp) {
                sendUserToBoltApp();
            }

        }

        onUserLngLatChanged();
    }, [userLngLat])

    /**
     * Called when the mapUiMode changes
     */
    useEffect(() => {
        function onMapUiModeChanged() {
            console.log("mapUiMode changed", mapUiMode)
            if (!map?.current?.map) return; // No map

            //map.current.showDirections(mapUiMode === 1)

            if (mapUiMode === 2 || mapUiMode === 3 || mapUiMode === 4 || mapUiMode === 5)
                map.current.setCursorCrossHair();
            else
                map.current.resetCursor();
        }

        onMapUiModeChanged();
    }, [mapUiMode])


    useEffect(() => {

        function onRouteNavigatorOnRouteCoordsChanged() {
            map?.current?.setSourceDataMultiPointFromCoordinates("routeNavigatorOnRouteCoords", routeNavigatorOnRouteCoords);
        }

        onRouteNavigatorOnRouteCoordsChanged();
    }, [routeNavigatorOnRouteCoords])

    useEffect(() => {

        function onRouteNavigatorOffRouteCoordsChanged() {
            map?.current?.setSourceDataMultiPointFromCoordinates("routeNavigatorOffRouteCoords", routeNavigatorOffRouteCoords);
        }

        onRouteNavigatorOffRouteCoordsChanged();
    }, [routeNavigatorOffRouteCoords])

    /**
     * Called when stuff changes. THis is the main init function
     */
    useEffect(() => {
        function onCreate() {
            console.log("<< onCreate")

            if (map?.current?.map) return;

            let lastLat = localStorage.getItem("lastLat");
            let lastLon = localStorage.getItem("lastLon");
            let lastZoom = localStorage.getItem("lastZoom");
            if (!lastLat || !lastLon || !lastZoom) {
                lastLat = -27.59716913712925;
                lastLon = 153.12954314351435;
                lastZoom = 13;
            }

            // Create the MapBox map object
            let mapboxMap = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/outdoors-v11',
                center: [lastLon, lastLat],
                zoom: lastZoom
            });

            // Create our wrapper map object
            map.current = new MapView(mapboxMap, mapboxgl.accessToken);

            // Add on move listeners
            map.current.map.on('move', () => {
                let lastLat = map.current.map.getCenter().lat;
                let lastLon = map.current.map.getCenter().lng;
                let lastZoom = map.current.map.getZoom();
                localStorage.setItem("lastLat", lastLat);
                localStorage.setItem("lastLon", lastLon);
                localStorage.setItem("lastZoom", lastZoom);
            });

            // Add on load listeners
            map.current.map.on('load', () => {
                console.log('onMapLoaded');
                setMapLoaded(true);
            });

            // Add on click listeners
            map.current.map.on('click', (e) => {
                // We cannot get the state, but we can set the state
                setMapLastClickLngLat(e.lngLat);
            });

        }

        onCreate();

    }, []);

    function onRouteControlModeClicked() {
        console.log("<< onRouteControlModeClicked", mapUiMode);
        if (mapUiMode === 2)
            setMapUiMode(0); // None
        else
            setMapUiMode(2); // Route
    }

    function onRouteControlClearClicked() {
        console.log("<< onRouteControlClearClicked");
        setRouteWaypoints([]); // This will
    }

    function onRouteControlCompressClicked() {
        console.log("<< onRouteControlCompressClicked");

        let navigator = routeNavigatorRef.current;

        if (!navigator) {
            return;
        }

        let cruxRouteOld = navigator.getCruxRoute();

        let cruxRouteNew = cruxRouteOld.decimate(1)
        cruxRouteNew.calculateClimbs();

        setRouteDirections(cruxRouteNew);

    }


    /** Sends the user to BoltApp, this does not check the 'autoSendUserToBoltApp' state*/
    function sendUserToBoltApp() {

        if (userLngLat)
            BoltAppApi.sendLocationToBoltApp(userLngLat);
    }

    function onRouteControlSendToBoltAppClicked() {
        console.log("onRouteControlSendToBoltAppClicked")

        if (!BoltAppApi.hasBoltAppHost()) {
            setShowBoltAppApiCfg(true)
            return;
        }

        let navigator = routeNavigatorRef.current;
        let cruxRoute = navigator?.getCruxRoute();
        if (cruxRoute) {
            console.log("onRouteControlSendToBoltAppClicked send route")
            if (cruxRoute.getBestDistanceM() < 100000) {
                let path = "/sendLocationToBoltApp.fit";
                cruxRoute.saveToFit(path, CruxJs.getAbsTimeMs())
                let fileData = cruxJs.readBytesFromFile(path);
                BoltAppApi.sendRouteToBoltApp(fileData.buffer_js);
                fileData.destroy();
            } else {
                console.log("onRouteControlSendToBoltAppClicked route too long")
            }
        } else {
            console.log("onRouteControlSendToBoltAppClicked clear route")
            BoltAppApi.sendRouteToBoltApp([]);
        }

    }

    function onUserControlSendToBoltAppClicked() {
        console.log("onUserControlSendToBoltAppClicked")

        if (!BoltAppApi.hasBoltAppHost()) {
            setShowBoltAppApiCfg(true)
            return;
        }

        // Get the new value
        let newAutoSendUserToBoltApp = !autoSendUserToBoltApp;

        // Set the state
        setAutoSendUserToBoltApp(newAutoSendUserToBoltApp);

        if (newAutoSendUserToBoltApp) {
            // We are going from a 'don't send user to BoltApp' state to a 'send user to BoltApp' state
            // If we have a pre-existing user, send it
            sendUserToBoltApp();
        }

    }


    function onRideControlClearClicked() {
        console.log("<< onRideControlClearClicked", mapUiMode);
        setRideWaypoints([]);
        setRideFreepoints([]);
    }

    function onPoll250ms() {

        updateStatusTxt(); // Not sure about this!

        if (rideIsPlaying && ridePlayer) {
            let locationPointObj = ridePlayer.poll250ms();
            if (locationPointObj) {
                setUserLngLat({lng: locationPointObj.lon_deg, lat: locationPointObj.lat_deg, elevM: locationPointObj.elevation_m})
            }
        }
    }

    useInterval(() => {


        onPoll250ms();
    }, 250);

    function onUserControlPlayRideClicked() {

        console.log("<< onUserControlPlayRideClicked");

        if (ridePlayer) {
            setRideIsPlaying(!rideIsPlaying);
        } else {
            setRideIsPlaying(false);
        }
    }

    function onRideRoadControlModeClicked() {
        console.log("<< onRideRoadControlModeClicked", mapUiMode);
        if (mapUiMode === 3)
            setMapUiMode(0); // None
        else
            setMapUiMode(3); // Road-base ride
    }

    function onRideFreeControlModeClicked() {
        console.log("<< onRideFreeControlModeClicked", mapUiMode);
        if (mapUiMode === 5)
            setMapUiMode(0); // None
        else
            setMapUiMode(5); // Freeform ride
    }

    function onUserControlModeClicked() {
        console.log("<< onUserControlModeClicked", mapUiMode);
        if (mapUiMode === 4)
            setMapUiMode(0); // None
        else
            setMapUiMode(4); // User
    }

    function onUserControlRideSpeedChanged() {

        let newRideUserSpeedKph;
        if (rideUserSpeedKph === 60)
            newRideUserSpeedKph = 0;
        else if (rideUserSpeedKph < 10)
            newRideUserSpeedKph = rideUserSpeedKph + 2;
        else
            newRideUserSpeedKph = rideUserSpeedKph + 10;

        console.log("<< onUserControlRideSpeedChanged", newRideUserSpeedKph, "kph")

        setRideUserSpeedKph(newRideUserSpeedKph)
        ridePlayer?.setSpeedMps(newRideUserSpeedKph * 0.277778);
    }

    /**
     * @param {String} fileName
     * @param {ArrayBuffer} fileBytes
     */
    function onRouteFitLoaded(fileName, fileBytes) {

        console.log("<< onRouteFitLoaded", fileName, fileBytes.byteLength);


        let routeFilePath = "/" + fileName;
        cruxJs.writeBytesToFile(routeFilePath, fileBytes);

        let cruxRoute = new CruxRoute(cruxJs);
        cruxRoute.loadFromFit(routeFilePath);
        cruxRoute.calculateClimbs();

        setRouteDirections(cruxRoute);

        let lp0 = cruxRoute.getLocationPoint(0);
        map?.current?.flyTo({lat: lp0.lat_deg, lng: lp0.lon_deg})

    }

    function updateStatusTxt() {

        let txt = "";

        txt += "ROUTE\n";
        let navigator = routeNavigatorRef.current;
        if (navigator) {
            let cruxRoute = navigator.getCruxRoute();
            let distanceIntoRouteM = navigator.getDistanceIntoRouteM();
            let distanceM = cruxRoute.getBestDistanceM();

            let progressStr = "";
            if (distanceIntoRouteM >= 0) {
                let progress = distanceIntoRouteM * 100 / distanceM;
                progressStr = progress.toFixed(0) + "%";
            }

            if (distanceM > 1000)
                txt += (distanceM / 1000).toFixed(1) + "km ";
            else
                txt += distanceM.toFixed(0) + "m ";
            txt += cruxRoute.getLocationPointCount() + "pts ";
            txt += cruxRoute.getCoursePointCount() + "turns ";
            txt += cruxRoute.getClimbCount() + "climbs\n"
            txt += navigator.getStateStr() + " " + progressStr + "\n";
            let nextCoursePoint = navigator.getNextCoursePoint();
            if (nextCoursePoint) {
                let instruction = nextCoursePoint.instruction ? nextCoursePoint.instruction : nextCoursePoint.street_name;
                txt += "Next CP: " + instruction + " " + navigator.getNextCoursePointDistanceM().toFixed(0) + "m\n";
            } else {
                txt += "Next CP: na\n";
            }

            txt += "Work: " + navigator.getDoWorkCount() + "\n";
        } else {
            txt += "No route\n";
        }


        txt += "\n";
        txt += "RIDE\n";
        let cruxRoute = ridePlayer?.getCruxRoute();
        if (cruxRoute) {
            txt += cruxRoute.getBestDistanceM().toFixed(0) + "m\n";
        } else {
            txt += "No ride\n";
        }

        txt += "\n";
        txt += "CRUX\n";
        txt += "Mem: " + (cruxJs.getHeapSize() / 1024).toFixed(1) + "KB\n";

        setStatusTxt(txt)
    }

    function onRouteNavigatorStateChangedFromCrux(state) {
        console.log("<< onRouteNavigatorStateChangedFromCrux state=" + state);
    }

    function onRouteNavigatorCoursePointImminentFromCrux() {
        console.log("<< onRouteNavigatorCoursePointImminentFromCrux");

        let nextCoursePoint = routeNavigatorRef.current.getNextCoursePoint();
        let instruction = nextCoursePoint.instruction ? nextCoursePoint.instruction : nextCoursePoint.street_name;

        setNextCoursePointToastText(instruction);

        //log("CoursePt: " + routeNavigatorRef.current?.getStateStr()); // TODO
    }

    function onRouteNavigatorLocationProcessedFromCrux() {
        console.log("<< onRouteNavigatorLocationProcessedFromCrux");

        let navigator = routeNavigatorRef.current;
        if (!navigator) return;

        let closestPoint = navigator.getClosestRouteLocationPoint();
        map?.current?.setRouteClosestPoint(closestPoint)

        let userLocationPoint = navigator.getUserLocationPoint();

        switch (navigator.getState()) {
        case crux_route_navigator_state_e.ON_ROUTE:
        case crux_route_navigator_state_e.ON_ROUTE_PROB: {
            routeNavigatorOnRouteCoords.push([userLocationPoint.lon_deg, userLocationPoint.lat_deg])
            setRouteNavigatorOnRouteCoords([...routeNavigatorOnRouteCoords]); // We need to COPY the array otherwise to won't react
            setOnOffRouteColor("green");
            break;
        }
        case crux_route_navigator_state_e.OFF_ROUTE:
        case crux_route_navigator_state_e.OFF_ROUTE_PROB:
        case crux_route_navigator_state_e.COMPLETE:
        default:
            routeNavigatorOffRouteCoords.push([userLocationPoint.lon_deg, userLocationPoint.lat_deg])
            setRouteNavigatorOffRouteCoords([...routeNavigatorOffRouteCoords]); // We need to COPY the array otherwise to won't react
            setOnOffRouteColor("orange",);
            break;
        }


    }

    function onRideFitLoaded(fileName, fileBytes) {
        console.log("<< onRideFitLoaded", fileName, fileBytes.byteLength);
        let routeFilePath = "/" + fileName;
        cruxJs.writeBytesToFile(routeFilePath, fileBytes);

        let cruxRoute = new CruxRoute(cruxJs);
        cruxRoute.loadFromFit(routeFilePath);

        // I don't know why we need to do this?
        if (rideDirections instanceof CruxRoute) {
            rideDirections.destroy();
        }
        setRideWaypoints([]);
        setRideFreepoints([]);
        setRideDirections(cruxRoute);


        let lp0 = cruxRoute.getLocationPoint(0);
        map?.current?.flyTo({lat: lp0.lat_deg, lng: lp0.lon_deg})
    }

    function onRouteControlUndoClicked() {
        console.log("<< onRouteControlUndoClicked")
        routeWaypoints.pop()
        setRouteWaypoints([...routeWaypoints]); // We need to COPY the array otherwise to won;t react
    }

    function onRideControlUndoClicked() {
        console.log("<< onRideControlUndoClicked")
        rideWaypoints.pop()
        rideFreepoints.pop()
        setRideWaypoints([...rideWaypoints]); // We need to COPY the array otherwise to won;t react
        setRideFreepoints([...rideFreepoints]); // We need to COPY the array otherwise to won;t react
    }

    function onRideControlCopyClicked() {
        console.log("<< onRideControlCopyClicked")


        let navigator = routeNavigatorRef.current;
        if (!navigator) {
            return;
        }

        let cruxRouteSrc = navigator.getCruxRoute();

        cruxRouteSrc.saveToFit("/copy.fit", CruxJs.getAbsTimeMs())

        let cruxRoute = new CruxRoute(cruxJs);
        cruxRoute.loadFromFit("/copy.fit");
        cruxRoute.calculateClimbs();

        // I don't know why we need to do this?
        if (rideDirections instanceof CruxRoute) {
            rideDirections.destroy();
        }
        setRideDirections(cruxRoute);

        let lp0 = cruxRoute.getLocationPoint(0);
        map?.current?.flyTo({lat: lp0.lat_deg, lng: lp0.lon_deg})

    }

    function onUserControlFastForwardRideClicked() {
        console.log("<< onUserControlFastForwardRideClicked");

        console.log("start")
        let loopCount = 60 * 4; // How many 250ms polls
        let id = setInterval(() => {
            loopCount--;
            if (loopCount === 0) {
                console.log("done")
                clearInterval(id);
            }
            onPoll250ms();
        }, 1);
    }

    function onUserControlRestartRideClicked() {
        console.log("onUserControlRestartRideClicked")

        ridePlayer?.restart()

    }

    function onRouteControlDownloadClicked() {
        console.log("<< onRouteControlDownloadClicked");
        setShowDownloadRoute(true)
    }

    function onSettingsClicked() {
        console.log("<< onBoltAppCfgClicked");
        setShowBoltAppApiCfg(true)
    }

    function onClimbsClearClicked() {
        console.log("<< onClimbsClearClicked");
        map.current.addRouteClimbsFromCruxRoute(null, true)
    }

    function onBoltAppShowClicked() {
        console.log("<< onBoltAppShowClicked");

        setMapUiMode(0); // None

        BoltAppApi.sendFetchStuffFromBoltApp(map?.current);

    }

    function onClimbsGenerateClicked() {

        console.log("<< onClimbsGenerateClicked");

        if (!userLngLat) {
            console.log("onClimbsGenerateClicked no userLngLat")
            return;
        }

        console.log(">> ClimbDetector start")
        new ClimbDetector(cruxJs, userLngLat, function onComplete(cruxRoutes) {
            console.log("<< ClimbDetector onComplete")


            for (let i = 0; i < cruxRoutes.length; i++) {
                const cruxRoute = cruxRoutes[i];
                map.current.addRouteClimbsFromCruxRoute(cruxRoute, i === 0)
                cruxRoute.destroy();
            }
        }).start();


    }

    function refreshLayerVisibility() {
        console.log("refreshLayerVisibility")
        map?.current?.refreshLayerVisibility()
    }

    function downloadRouteOrRide(isRoute, fileType) {
        console.log("<< downloadRouteOrRide", fileType)

        let cruxRoute;

        if (isRoute) {
            cruxRoute = routeNavigator?.getCruxRoute();
            if (!cruxRoute) {
                alert("No route");
                return
            }
        } else {
            cruxRoute = ridePlayer?.getCruxRoute();
            if (!cruxRoute) {
                alert("No ride");
                return
            }
        }

        let name = cruxRoute.getName().replaceAll(' ', '_');
        let path = null;
        let fileData = null;
        switch (fileType) {

        case "FIT":
            path = "/" + name + ".fit";
            cruxRoute.saveToFit(path, CruxJs.getAbsTimeMs())
            fileData = cruxJs.readBytesFromFile(path);
            break
        case "JSON":
            path = "/" + name + ".json";
            cruxRoute.saveToJson(path, CruxJs.getAbsTimeMs())
            fileData = cruxJs.readBytesFromFile(path);
            break
        case "LocCSV":
            path = "/" + name + ".locations.csv";
            cruxRoute.saveLocationPointsToCsv(path)
            fileData = cruxJs.readBytesFromFile(path);
            break
        case "EleCSV":
            path = "/" + name + ".elevations.csv";
            cruxRoute.saveElevationPointsToCsv(path)
            fileData = cruxJs.readBytesFromFile(path);
            break
        case "ClimbsCSV":
            path = "/" + name + ".climbs.csv";
            cruxRoute.saveClimbsToCsv(path)
            fileData = cruxJs.readBytesFromFile(path);
            break
        case "CpCsv":
            console.log("CpCsv not support yet")
            break
        default:
            break;
        }

        if (fileData && path) {

            // See https://motley-coder.com/2019/04/01/download-files-emscripten/

            const a = document.createElement('a')
            a.style = 'display:none'
            document.body.appendChild(a)

            const blob = new Blob([fileData.buffer_js], {
                type: 'octet/stream'
            })

            const url = window.URL.createObjectURL(blob)
            a.href = url
            a.download = path.replace(/^.*[\\/]/, '');
            a.click()
            window.URL.revokeObjectURL(url)
            document.body.removeChild(a)

            // We must destroy the CruxByteArray or we'll get a wasmModule.HEAP memory leak
            fileData.destroy();

        }
    }

    return (
        <div>

            <div className="statusTxt" style={{"backgroundColor": onOffRouteColor}}>
                {statusTxt}
            </div>


            <div className="sidebar">

                <div className="sidebarSection">
                    <Badge className="sidebarSectionTitle" pill bg="danger">
                        Route
                    </Badge>
                    <MyButton tooltip="Build route" variant="outline-danger" selected={mapUiMode === 2} onClick={onRouteControlModeClicked} img={<FaRoute/>}/>
                    <MyButton tooltip="Remove last route point" variant="outline-danger" onClick={onRouteControlUndoClicked} img={<FaUndo/>}/>
                    <MyFileInput id="routeFitLoaded" variant="outline-danger" tooltip="Load route from FIT file" acceptExtension="fit" onFileSelectedAndLoaded={onRouteFitLoaded}/>
                    <MyButton tooltip="Download route" variant="outline-danger" onClick={onRouteControlDownloadClicked} img={<FaDownload/>}/>
                    <MyButton tooltip="Delete route" variant="outline-danger" onClick={onRouteControlClearClicked} img={<FaTrash/>}/>
                    <MyButton tooltip="Compress route" variant="outline-danger" onClick={onRouteControlCompressClicked} img={<FaCompressArrowsAlt/>}/>
                    <MyButton tooltip="Send route to BoltApp" variant="outline-danger" onClick={onRouteControlSendToBoltAppClicked} img={<MdSendToMobile/>}/>
                </div>

                <div className="sidebarSection">
                    <Badge className="sidebarSectionTitle" pill bg="primary">
                        Ride
                    </Badge>
                    <MyButton tooltip="Build road-based ride" variant="outline-primary" selected={mapUiMode === 3} onClick={onRideRoadControlModeClicked} img={<ImRoad/>}/>
                    <MyButton tooltip="Build free-form ride" variant="outline-primary" selected={mapUiMode === 5} onClick={onRideFreeControlModeClicked} img={<GiBroadheadArrow/>}/>
                    <MyButton tooltip="Remove last ride point" variant="outline-primary" onClick={onRideControlUndoClicked} img={<FaUndo/>}/>
                    <MyFileInput id="rideFitLoaded" tooltip="Load ride from FIT file" variant="outline-primary" acceptExtension="fit" onFileSelectedAndLoaded={onRideFitLoaded}/>
                    <MyButton tooltip="Download ride" variant="outline-primary" onClick={onRouteControlDownloadClicked} img={<FaDownload/>}/>
                    <MyButton tooltip="Delete ride" variant="outline-primary" onClick={onRideControlClearClicked} img={<FaTrash/>}/>
                    <MyButton tooltip="Copy ride from route" variant="outline-primary" onClick={onRideControlCopyClicked} img={<FaCopy/>}/>
                </div>

                <div className="sidebarSection">
                    <Badge className="sidebarSectionTitle" pill bg="success">
                        User
                    </Badge>
                    <MyButton tooltip="Select user location on map" variant="outline-success" selected={mapUiMode === 4} onClick={onUserControlModeClicked} img={<FaMapPin/>}/>
                    <MyButton tooltip="Play the ride" selected={rideIsPlaying} variant="outline-success" onClick={onUserControlPlayRideClicked} img={<FaPlay/>}/>
                    <MyButton tooltip="Restart ride" variant="outline-success" onClick={onUserControlRestartRideClicked} img={<BsFillSkipStartFill/>}/>
                    <MyButton tooltip="Fast forward ride by 1min" variant="outline-success" onClick={onUserControlFastForwardRideClicked} img={<FaFastForward/>}/>
                    <MyButton tooltip="Adjust ride speed" variant="outline-success" onClick={onUserControlRideSpeedChanged} img={rideUserSpeedKph + "kph"}/>
                    <MyButton tooltip="Enable auto-send user location to BoltApp" variant="outline-success" selected={autoSendUserToBoltApp} onClick={onUserControlSendToBoltAppClicked} img={<MdSendToMobile/>}/>
                </div>


                <div className="sidebarSection">
                    <Badge className="sidebarSectionTitle" pill bg="warning">
                        Climbs
                    </Badge>
                    <MyButton tooltip="Search the local area for around-me climbs (needs a user location)" variant="outline-warning" onClick={onClimbsGenerateClicked} img={<FaMountain/>}/>
                    <MyButton tooltip="Clears the around-me climbs" variant="outline-warning" onClick={onClimbsClearClicked} img={<FaTrash/>}/>
                </div>

                <div className="sidebarSection">
                    <Badge className="sidebarSectionTitle" pill bg="secondary">
                        BoltApp
                    </Badge>
                    <MyButton tooltip="Show what's happening in BoltApp" variant="outline-secondary" onClick={onBoltAppShowClicked} img={<BiShow/>}/>
                </div>

                <div className="sidebarSection">
                    <Badge className="sidebarSectionTitle" pill bg="info">
                        Settings
                    </Badge>
                    <MyButton tooltip="Settings" variant="outline-info" onClick={onSettingsClicked} img={<FaCog/>}/>
                </div>

            </div>


            <DownloadRouteOrRideModal show={showDownloadRoute} setShow={setShowDownloadRoute} onSelect={downloadRouteOrRide}/>
            <SettingsModal show={showBoltAppApiCfg} setShow={setShowBoltAppApiCfg} refreshLayerVisibility={refreshLayerVisibility}/>


            <Modal show={isBuildingMapboxRoute}>
                <Modal.Header closeButton>
                    <Modal.Title>Building route...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{}}>
                        <label>We need to go slow because Google Elevation API has a rate limitation</label><br/>
                    </div>
                </Modal.Body>
            </Modal>


            <div ref={mapContainer} className="map-container"/>

            <ToastContainer style={{"position": "absolute"}} position={"middle-center"}>
                <Toast show={nextCoursePointToastText} animation={false} delay={3000} autohide onClose={() => {
                    setNextCoursePointToastText("")
                }}>
                    <Toast.Header>
                        <strong className="me-auto">Course Point Approaching</strong>
                    </Toast.Header>
                    <Toast.Body>{nextCoursePointToastText}</Toast.Body>
                </Toast></ToastContainer>

            <ToastContainer style={{"position": "absolute"}} position={"middle-center"}>
                <Toast show={showWelcome} animation={false} onClose={() => {
                    setShowWelcome(false)
                }}>
                    <Toast.Header>
                        <strong className="me-auto">Welcome!</strong>
                    </Toast.Header>
                    <Toast.Body>
                        Hi! This app allow you to simulate Crux route navigation from the comfort of your desk.<br/><br/>
                        The basic idea is you create a <Badge pill bg="danger">Route</Badge> and then simulate the user's movement along the route using the <Badge pill bg="success">User</Badge> and/or <Badge pill bg="primary">Ride</Badge> controls.
                    </Toast.Body>
                </Toast></ToastContainer>

            <div className="elevChart" style={{display: elevData.length <= 2 ? "none" : ""}}>
                <Chart
                    chartType="ScatterChart"
                    data={elevData}
                    legendToggle
                    options={{
                        vAxis: {title: "Elevation (m)"},
                        hAxis: {title: "Distance (m)"},
                        series: {
                            0: {
                                type: "lines",
                                color: 'transparent'

                                // This sample shows how to toggle visibility http://jsfiddle.net/asgallant/6gz2Q/
                            },
                            1: {
                                type: "lines",
                                color: 'red',
                            },
                            2: {
                                type: "lines",
                                color: 'violet',
                            }
                        },
                    }}
                />
            </div>

        </div>
    );
}

