export class BoltAppApi {


    static getBoltAppHost() {
        let value = localStorage.getItem("BoltAppApi.BoltAppHost");
        return value ? value : "";
    }

    static hasBoltAppHost() {
        let boltAppHost = BoltAppApi.getBoltAppHost();
        return boltAppHost.length > 0;
    }

    /**
     *
     * @param lngLat an object containing fields lng, lat and optional elevM
     */
    static sendLocationToBoltApp(lngLat) {

        if (!this.hasBoltAppHost())
            return

        //console.log("sendLocationToBoltApp", lngLat)

        let url = BoltAppApi.getBoltAppHost() + "/BARouteManager/mock";

        let req;
        if (lngLat.elevM) {
            req = {
                latDeg: lngLat.lat,
                lonDeg: lngLat.lng,
                elevationM: lngLat.elevM,
            };
        } else {
            req = {
                latDeg: lngLat.lat,
                lonDeg: lngLat.lng,
            };
        }


        // https://jasonwatmore.com/post/2020/11/02/react-fetch-http-put-request-examples
        // Simple PUT request with a JSON body using fetch

        const requestOptions = {
            method: 'PUT',
            body: JSON.stringify(req)
        };

        fetch(url, requestOptions)
            .then(response => response.json())
            .then((data) => {
                //console.log(data)
            });
    }

    /**
     * Sends a route to BoltApp
     * @param fitBytes an object containing fields lng, lat
     */
    static async sendRouteToBoltApp(fitBytes) {


        if (!this.hasBoltAppHost()) {
            console.log("sendRouteToBoltApp no host")
            return;
        }

        console.log("sendRouteToBoltApp", fitBytes.length)

        let urlStart = BoltAppApi.getBoltAppHost() + "/BARouteManager/routeStart";
        let urlPart = BoltAppApi.getBoltAppHost() + "/BARouteManager/routePart";
        let urlEnd = BoltAppApi.getBoltAppHost() + "/BARouteManager/routeEnd";

        // NOTE: I found HUGE routes would mysteriously be corrupted, so we now split it into chunks

        const CHUNK_SIZE = 4096;

        try {
            for (let i = 0; i < fitBytes.length; i += CHUNK_SIZE) {
                const chunk = fitBytes.slice(i, i + CHUNK_SIZE);
                const response = await fetch(i === 0 ? urlStart : urlPart, {
                    method: 'PUT',
                    body: chunk,
                });
                //Zconsole.log("sendRouteToBoltApp", i, response.statusText);
            }

            const response = await fetch(urlEnd, {
                method: 'PUT',
                body: [],
            });
            console.log("sendRouteToBoltApp end", response.statusText);
        } catch (error) {
            alert(`Error uploading route (try again)`);
        }

    }


    static setBoltAppHost(value) {
        console.log("setBoltAppHost", value)
        localStorage.setItem("BoltAppApi.BoltAppHost", value);
    }

    static sendFetchStuffFromBoltApp(map) {


        // This is a WIP.
        // The hope is we can query the JSON state from the StdRouteManager, get the selected route,
        // then fetch the selected route, and render it
        // Also the segments, climbs, etc!!!!

        if (!this.hasBoltAppHost()) {
            console.log("sendFetchStuffFromBoltApp no host")
            return
        }


        // Selected Route
        {
            let url = BoltAppApi.getBoltAppHost() + "/BARouteManager/state";
            const requestOptions = {
                method: 'GET'
            };
            fetch(url, requestOptions)
                .then(response => response.json())
                .then((data) => {
                    console.log(data)

                    let flyToLngLat = null;

                    // selectedRoute locations
                    let locations = data?.selectedRoute?.coordinates?.locations;
                    map?.setBoltAppRouteFromCoords(locations)
                    if (locations?.length > 0) {
                        flyToLngLat = {lat: locations[0][1], lng: locations[0][0]}
                    }

                    let latDeg = data?.location?.latDeg;
                    let lonDeg = data?.location?.lonDeg;
                    if (latDeg && lonDeg) {
                        flyToLngLat = {lat: latDeg, lng: lonDeg};
                        map?.setBoltAppUserLngLat(flyToLngLat);
                    } else
                        map?.setBoltAppUserLngLat(null);

                    if (flyToLngLat) {
                        map?.flyTo(flyToLngLat)
                    }
                });
        }

        {

            for (let i = 0; i < 10; i++) {
                map?.setBoltAppClimbFromCoords(i, null)
                map?.setBoltAppSegmentFromCoords(i, null, null)
            }

            let url = BoltAppApi.getBoltAppHost() + "/JStdEffortManager/state";
            const requestOptions = {
                method: 'GET'
            };
            fetch(url, requestOptions)
                .then(response => response.json())
                .then((data) => {

                    console.log(data)

                    let climbIdx = 0
                    let segmentIdx = 0

                    // climbs
                    let efforts = data?.efforts;

                    //console.log(efforts)

                    let locations = data?.road?.coordinates?.locations;
                    map?.setBoltAppRouteFromCoords(locations)

                    let effortCount = Math.min(10, efforts?.length);
                    for (let i = 0; i < effortCount; i++) {

                        let effort = efforts?.[i];
                        //console.log(effort)
                        if (!effort)
                            continue;

                        let effortId = effort?.effortId;
                        //console.log(effortId)

                        let providerType = effortId?.providerType;
                        if (!providerType) {
                            console.log("no providerType")
                            continue;
                        }


                        let locations = effort.coordinates?.locations

                        if (providerType.toLowerCase().indexOf("strava") !== -1) {
                            let name = "Segment: " + effort.name
                            map?.setBoltAppSegmentFromCoords(segmentIdx++, locations, name)
                        } else {
                            let classification = effort.classification
                            let ascentM = effort.ascentM
                            let enabled = effort.enabled
                            let name = "Climb: " + effort.name + " " + classification.toLowerCase() + " " + ascentM.toFixed(0) + "m " + (enabled ? "enabled" : "disabled");
                            map?.setBoltAppClimbFromCoords(climbIdx++, locations, name)
                        }


                    }


                    // onSelectedRouteCoordsRcvd(data?.coordinates?.locations)
                });
        }

    }
}