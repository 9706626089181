import elevationApi from "google-elevation-api";

export class GoogleElevationApi {

    /**
     * Takes the JSON response to a Mapbox directions request and asks Google for the elevations
     * The mapbox location points are in:
     * mapboxDirections.routes[0].geometry.coordinates
     * This function will put Google's results in:
     * mapboxDirections.routes[0].geometry.coordinates2
     * @param mapboxDirections the mapbox directions
     * @param onComplete a function callback
     */
    static fetchElevations(mapboxDirections, onComplete) {

        //  See https://www.npmjs.com/package/google-elevation-api


        if (!mapboxDirections?.routes) {
            console.log("fetchElevations no route");
            onComplete(mapboxDirections)
            return;
        }

        //
        // Google only allows request containing a max of 512 points, so we need to split our request into 512 length sub requests
        //

        // Build a list of 512 length elevation requests
        let coordinates = mapboxDirections.routes[0].geometry.coordinates;
        let reqs = [];
        {
            let req = null;
            for (let i = 0; i < coordinates.length; i++) {
                if (!req || i % 512 === 0) {
                    req = {
                        key: 'AIzaSyCkJ9Y7d5-AB9ebowUi7IyMUe-WiRm4XaA',
                        locations: []
                    };
                    reqs.push(req)
                }
                req.locations.push([coordinates[i][1], coordinates[i][0]]);
            }
        }


        // This is the array that will be built over our several calls to the Google API
        let allGoogleLocations = [];

        // This function take a
        this.callNextElevationApi(reqs, allGoogleLocations, function () {

            let coordinates2 = [];
            allGoogleLocations.forEach(googleLocation => {

                let latDeg = googleLocation.location[0];
                let lonDeg = googleLocation.location[1];
                let elevM = googleLocation.elevation;
                coordinates2.push([latDeg, lonDeg, elevM])

            });

            mapboxDirections.routes[0].geometry.coordinates2 = coordinates2

            onComplete(mapboxDirections)

        })


    }

    /**
     * This function takes a list of requests, and executes them one at a time, until they are all complete, then it calls onComplete()
     * This function is re-entrant
     * @param reqs the list of remaining requests
     * @param allGoogleLocations the list of Google locations that will be built across all the requests
     * @param onComplete callback function called when all the requests are complete
     */
    static callNextElevationApi(reqs, allGoogleLocations, onComplete) {

        console.log("callNextElevationApi reqs.length=" + reqs.length, "allGoogleLocations.length=" + allGoogleLocations.length)
        var elevationApi = require('google-elevation-api');

        let req = reqs.shift();

        console.log(">> fetch google req")
        elevationApi(req, function (err, googleLocations) {

            if (err) {
                console.log("<< fetch google rsp FAILED", err)
                onComplete()
                return;
            }
            console.log("<< fetch google rsp OK", googleLocations.length)

            for (let i = 0; i < googleLocations.length; i++) {
                allGoogleLocations.push(googleLocations[i])
            }

            if (reqs.length > 0) {
                setTimeout(function() {
                    // function to be executed after 1 second
                    GoogleElevationApi.callNextElevationApi(reqs, allGoogleLocations, onComplete);
                }, 3000); // 1000 milliseconds = 1 second

            } else {
                onComplete();
            }


        });
    }
}