import {GoogleElevationApi} from "./GoogleElevationApi";

export class MapBoxApi {

    /**
     *
     * @param waypointsLngLat an array of objects possessing a lng and lat field
     * @param onComplete a function accepting a JSON object
     */
    static fetchDirections(waypointsLngLat, onComplete) {

        // See https://docs.mapbox.com/playground/directions/

        if (waypointsLngLat.length < 2)
            return

        // See https://docs.mapbox.com/playground/directions/

        let apiUrl = 'https://api.mapbox.com/directions/v5/mapbox/cycling/';

        waypointsLngLat.forEach(function (waypointLngLat, idx, array) {
            apiUrl += waypointLngLat.lng +
                '%2C' +
                waypointLngLat.lat;
            if (idx < array.length - 1) {
                apiUrl += '%3B';
            }
        });

        apiUrl += '?alternatives=false&' +
            'geometries=geojson&' +
            'language=en&' +
            'overview=full&' +
            'exclude=ferry&' +
            'steps=true&' +
            'access_token=pk.eyJ1Ijoid2Fob29maXRuZXNzIiwiYSI6ImNqMXFvZ3dydTAwaTQzNXZlcWJlNjdoZnQifQ.3KR1FPpntw4u4EUF9bAQKQ';

        console.log(">> fetch mapbox req")
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                console.log("<< fetch mapbox rsp")
                onComplete(data);
            });

        // See https://docs.mapbox.com/playground/directions/
    }


    static fetchDirectionsAndElevations(waypointsLngLat, onComplete) {
        console.log(">> MapBoxApi fetchDirections in fetchDirectionsAndElevations")
        MapBoxApi.fetchDirections(waypointsLngLat, mapboxDirections => {
            console.log("<< MapBoxApi fetchDirections", mapboxDirections ? "OK" : "FAILED")
            if (mapboxDirections) {
                console.log(">> GoogleElevationApi fetchElevations")

                GoogleElevationApi.fetchElevations(mapboxDirections, function onGoogleComplete(mapboxDirections) {
                        console.log("<< GoogleElevationApi fetchElevations")
                        onComplete(mapboxDirections);
                    }
                )
            } else {
                onComplete(mapboxDirections);
            }
        })
    }
}