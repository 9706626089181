import {FaUpload} from "react-icons/fa";
import {useRef} from "react";
import MyButton from "./MyButton";
import React from "react";

function MyFileInput({tooltip, acceptExtension, onFileSelectedAndLoaded, id, variant}) {

    const map2 = useRef(null);

    const onFileSelected = (file) => {
        console.log("onFileSelected " + file);

        // See https://stackoverflow.com/a/16937439/5845266
        var reader = new FileReader();
        reader.onload = (function (file) {
            var fileName = file.name;
            return function (e) {
                onFileSelectedAndLoaded(fileName, e.target.result);
            };
        })(file);
        reader.readAsArrayBuffer(file);
    }

    function onFileChanged(e) {
        console.log("<< onFileChanged")
        // https://attacomsian.com/blog/javascript-iterate-filelist
        for (let i = 0; i < e.target.files.length; i++) {
            let file = e.target.files.item(i);
            onFileSelected(file);
        }
    }

    // See https://stackoverflow.com/questions/572768/styling-an-input-type-file-button
    // Actually see https://stackoverflow.com/a/18803568/5845266

    function onInputClicked(e) {
        // https://stackoverflow.com/a/40429197/5845266
        e.target.value = null
    }

    function onButtonClicked() {
        map2.current.click()
    }

    return (
        <>
            <MyButton size="sm" tooltip={tooltip} variant={variant} onClick={onButtonClicked} img={<FaUpload/>}/>
            <input
                ref={input => {
                    map2.current = input
                }}
                onClick={onInputClicked}
                style={{
                    display: 'none',
                }}
                id={id}
                type="file"
                accept={acceptExtension}
                onChange={onFileChanged}
                multiple/>
        </>
    );
}

export default MyFileInput;
