import {Modal} from "react-bootstrap";
import {BoltAppApi} from "../utils/BoltAppApi";
import useState from "react-usestateref";
import React, {useEffect} from 'react';
import Collapsible from "react-collapsible";
import {ClimbDetector} from "../utils/ClimbDetector";

export function SettingsModal(props) {

    const [boltAppHost, setBoltAppHost] = useState();
    const [climbRouteRadiusM, setClimbRouteRadiusM] = useState();
    const [showMapLabels, setShowMapLabels] = useState();

    useEffect(() => {
        function onBoltAppHostChanged() {
            if (boltAppHost === undefined) return
            console.log("SettingsModal onBoltAppHostChanged", boltAppHost)
            BoltAppApi.setBoltAppHost(boltAppHost)
        }

        onBoltAppHostChanged();
    }, [boltAppHost])


    useEffect(() => {
        function onClimbRouteRadiusM() {
            if (climbRouteRadiusM === undefined) return
            console.log("SettingsModal onClimbRouteRadiusM", climbRouteRadiusM)
            ClimbDetector.setClimbRouteRadiusM(climbRouteRadiusM)
        }

        onClimbRouteRadiusM();
    }, [climbRouteRadiusM])

    useEffect(() => {
        function onShowMapLabelsChanged() {
            if (showMapLabels === undefined) return
            console.log("SettingsModal onShowMapLabelsChanged", showMapLabels)
            localStorage.setItem("showMapLabels", showMapLabels)
            props.refreshLayerVisibility();
        }

        onShowMapLabelsChanged();

    }, [showMapLabels])

    useEffect(() => {
        function onCreate() {
            console.log("SettingsModal onCreate")
            setBoltAppHost(BoltAppApi.getBoltAppHost())
            setClimbRouteRadiusM(ClimbDetector.getClimbRouteRadiusM())
            setShowMapLabels(localStorage.getItem("showMapLabels") === "true")
        }

        onCreate();
    }, [])


    // See https://www.w3schools.com/react/react_forms.asp

    return (

        <Modal show={props.show} onHide={() => {
            props.setShow(false)
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div>
                    <b>Configure BoltApp Wifi Connection</b>

                    <form>
                        <label>Enter your BoltApp hostname (eg "http://10.0.0.32:8080"):
                            <input style={{width: "100%"}}
                                   type="text"
                                   value={boltAppHost ? boltAppHost : ""}
                                   onChange={(e) => setBoltAppHost(e.target.value)}
                            />
                        </label>
                    </form>

                    <Collapsible trigger="What is this?" triggerStyle={{color: "blue"}}>
                        BoltApp runs a little web server allowing this site to <b>mock locations</b> and <b>create/select routes</b> directly on the bike computer.<br/>
                        To start this web server run the ADB command:<br/>

                        <div style={{margin: 10, fontSize: "small", backgroundColor: "lightgray"}}>
                            adb shell am broadcast -a com.wahoofitness.support.webserver.StdWebServerManager.START
                        </div>

                        Once running, you can get the web server's hostname from the BoltApp log by running:
                        <div style={{margin: 10, fontSize: "small", backgroundColor: "lightgray"}}>
                            adb logcat | grep StdWebServerManager
                        </div>

                        NOTE: if using a Chrome based browser, you may need to "allow insecure content" in Chrome's "site settings" for this site.
                        <div style={{margin: 10, fontSize: "small", backgroundColor: "lightgray"}}>
                            chrome://settings/content/siteDetails?site=https%3A%2F%2Fcrux-react-app-routing.herokuapp.com
                        </div>
                    </Collapsible>

                </div>

                <div>
                    <br/>
                    <b>Climbs</b>

                    <form>
                        <label>Climb search radius (m):
                            <input style={{width: "100%"}}
                                   type="text"
                                   value={climbRouteRadiusM ? climbRouteRadiusM : 1000}
                                   onChange={(e) => {
                                       let radiusM = parseInt(e.target.value);
                                       if (!isNaN(radiusM))
                                           return setClimbRouteRadiusM(radiusM);
                                   }}
                            />
                        </label>

                    </form>

                    <Collapsible trigger="What is this?" triggerStyle={{color: "blue"}}>
                        Searching for around-me routes requires us to generate 12 routes in all directions from the user (like a clock-face).
                        This setting defines how long these 12 routes should be.<br/>
                    </Collapsible>

                </div>


                <div>
                    <b>Map</b>

                    <form>
                        <label>Show labels
                            <input
                                type="checkbox"
                                checked={showMapLabels}
                                onChange={(e) => setShowMapLabels(e.target.checked)}
                            />
                        </label>
                    </form>


                </div>

            </Modal.Body>

        </Modal>

    );


}